import { useEffect, useState, useContext, ReactElement } from "react";
import { dispatchError } from "../../common/fx";
import { UIContext, UIState } from "../../providers/UIProvider";
import { GetReports } from "../../apiCalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  CssBaseline,
  ThemeProvider 
} from "@mui/material";
import { mainTheme } from "../../styles/MainTheme";
import {
  Apps,
  ExpandMore,
  Extension,
  FactCheck,
  Handyman,
  Home,
  HomeRepairService,
  People,
  UnfoldLess,
  UnfoldMore
} from '@mui/icons-material';
import "./DynamicReports.scss";
import { useNavigate } from "react-router-dom";

type ReportCategory = {
  key: string;
  name: string;
  icon: ReactElement;
  isExpanded?: boolean;
};

const ReportCategories:ReportCategory[] = [
  { key: "Job", name: "Job Reports", icon: <FactCheck /> },
  { key: "Change Order", name: "Change Order Reports", icon: <Home /> },
  { key: "Option", name: "Options Reports", icon: <HomeRepairService /> },
  { key: "Manual Option", name: "Manual Option Reports", icon: <Handyman /> },
  { key: "Community", name: "Community Reports", icon: <People /> },
  { key: "Miscellaneous", name: "Miscellaneous Reports", icon: <Extension /> },
];

export default function DynamicReports() {
  const [reportCategories, setReportCategories] = useState<any[]>([]);
  const [reports, setReports] = useState<any[]>([]);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: `Dynamic Reports` },
    });

    GetReports(
      (response: any) => {
        const newCategories : ReportCategory[] = [];
        response.data.forEach((report:any) => {
          if(!newCategories.some((category:ReportCategory) => category.key === report.category)) {
            const catObj:ReportCategory = 
              ReportCategories.find((cat:ReportCategory) => cat.key === report.category) ??
              { key: report.category, name: report.category, icon: <Apps /> };
            catObj.isExpanded = true;
            newCategories.push(catObj);
          }
        });
        setReportCategories(newCategories);
        const newReports:any[] = response.data.sort((objA:any, objB:any) => {
          if (objA.category !== objB.category)
            return objA.category.localeCompare(objB.category);
          return 0;
        });
        setReports(newReports);
      },
      (err: any) => {
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        );
      }
    );
  }, []);

  const openReport = (report:any) => {
    navigate(`../report/${report.reportName}`);
  };

  const handleExpanderToggle = (expanderId:string) => {
    const newReportCategories = [ ...reportCategories ];
    const toggledIx = newReportCategories.findIndex((cat:ReportCategory) => cat.key === expanderId);
    newReportCategories[toggledIx].isExpanded = !newReportCategories[toggledIx].isExpanded;
    setReportCategories(newReportCategories);
  }

  const SwitchAllExpanders = (switchTo:boolean) => {
    setReportCategories(reportCategories.map((cat:ReportCategory) => ({ ...cat, isExpanded: switchTo })))
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          my: mainTheme.spacing(2),
        }}
        component="main"
        maxWidth={false}
      >
        <CssBaseline />
        <Box color={mainTheme.palette.primary.main} className="dr--ctas">
          <Button
            disabled={reportCategories.length === 0}
            variant="text"
            onClick={() => SwitchAllExpanders(false)}
          >
            <UnfoldLess /> Contract All
          </Button>
          <Button
            disabled={reportCategories.length === 0}
            variant="text"
            onClick={() => SwitchAllExpanders(true)}
          >
            <UnfoldMore /> Expand All
          </Button>
        </Box>
        { reportCategories.length > 0 && 
          reportCategories.map((category:ReportCategory) =>
            <Accordion
              className="themed--accordion"
              expanded={category.isExpanded}
              key={category.key}
              onChange={() => handleExpanderToggle(category.key)}
            >
              <AccordionSummary
                aria-controls={`drc-${category.key}`}
                className="themed--accordion--summary"
                expandIcon={<ExpandMore />}
                id={`drc-${category.key}`}
              >
                <h3>{ category.name }</h3>
              </AccordionSummary>
              <AccordionDetails className="dr--tile-wrapper">
                {
                  reports
                    .filter((report:any) => report.category === category.key)
                    .map((report:any) => {
                      const reportCategory = reportCategories.find((rep:ReportCategory) => rep.key === report.category);
                      return (
                        <div
                          className="dr--tile"
                          key={report.id}
                          onClick={() => openReport(report)}
                        >
                          <div className="dr--icon">
                            {reportCategory.icon}
                          </div>
                          <div className="dr--info">
                            <h3>{ report.displayName }</h3>
                            <p>{ report.description}</p>
                          </div>
                        </div>
                      );
                    })
                }
              </AccordionDetails>
            </Accordion>
          )
        }
      </Container>
    </ThemeProvider>
  );
};
import {
  Autocomplete,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
  //LinearProgress
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { filter, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { numberWithCommas } from "../../../utils/formatMoney";
import {
  CommunityTypes,
  UIContext,
  UIState,
} from "../../../providers/UIProvider";
import { jobsApiFiltered } from "../../../apiCalls";
import { FormikProps } from "formik";
import LoadingGridOverlay from "../../../components/Loading/LoadingGridOverlay";
import useAccessControl from "../../../hooks/useAccessControl";
import { filterOptions } from "../../../utils/filterOptions";

type Props = {
  setSelectedHomes: (
    value: {
      id: number;
      address: string;
      currentSalesPrice: number;
    }[]
  ) => void;
  formik: FormikProps<{
    adjustmentReason: string;
    adjustAmount: number;
    adjustBy: "percentage" | "dollar";
    selectedHomes: any;
  }>;
};

const Step1 = ({ setSelectedHomes, formik }: Props) => {
  const jobsApiFilteredApiAccess = useAccessControl("Job", "Get");

  const [state, dispatch] = React.useContext<UIState | any>(UIContext);

  const [notSold, setNotSold] = React.useState([]);

  const [paginationModelNotSold, setPaginationModelNotSold] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [search] = React.useState<string>("");
  const [communities, setCommunities] = React.useState<string[]>([]);
  const [loadingNotSold, setLoadingNotSold] = React.useState<boolean>(true);

  const [filtNotSold] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  const fetchAddresses = (
    paginationModel: {
      page: number;
      pageSize: number;
    },
    jobType: string,
    setArr: any,
    setLoading: any
  ) => {
    setLoading(true);
    jobsApiFiltered(
      {
        searchText: search,
        communities: communities,
        jobType: jobType,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      },
      (res: any) => {
        const uniqueOnly = Array.from(
          new Map(
            res.data.summaries.map((job: any) => [job["id"], job])
          ).values()
        );
        setArr(uniqueOnly);
        setLoading(false);
      },
      (res: any) => {
        console.log(res);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        });
      }
    );
  };
  const handleInputChange = (e: any) => {
    const { value } = e.target;
    // Allow numbers only, with up to two decimal places
    const validValue = value.match(/^[-]?\d*\.?\d{0,2}/);

    // Update Formik's state only if the value matches the pattern
    if (validValue) {
      formik.setFieldValue("adjustAmount", validValue[0]);
    }
  };

  useEffect(() => {
    communities.length >= 1 &&
      jobsApiFilteredApiAccess &&
      fetchAddresses(
        paginationModelNotSold,
        "NotSold",
        setNotSold,
        setLoadingNotSold
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationModelNotSold,
    filtNotSold,
    search,
    communities,
    jobsApiFilteredApiAccess,
  ]);

  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: "Address",
      width: 250,
      flex: 1,
    },
    {
      field: "planNumber",
      headerName: "Plan",
      flex: 1,
    },
    {
      field: "planElevationNumber",
      headerName: "Elevation",
      flex: 1,
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 1,
    },
    {
      field: "newContractPrice",
      headerName: "Current Price",
      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,

      maxWidth: 150,

      flex: 1,
    },
    {
      field: "adjustedSalesPrice",
      headerName: "Adjusted Price",
      maxWidth: 150,
      flex: 1,
      valueGetter: (params: any) => {
        console.log(formik.values.adjustAmount);
        return `${
          formik.values.adjustAmount && params.row.newContractPrice
            ? (
                Number(params.row.newContractPrice) +
                Number(
                  String(formik.values.adjustAmount) === "-"
                    ? 0
                    : formik.values.adjustAmount
                )
              ).toFixed(2)
            : params.row.newContractPrice
        }`;
      },
      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,
    },
  ];

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationModelNotSold((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModelNotSold((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };
  return (
    <Stack
      gap={2}
      justifyContent={"space-evenly"}
      flexDirection={"column"}
      sx={{
        alignItems: "center",
        flexDirection: { xs: "column", md: "column" },
        justifyContent: { xs: "center", md: "space-between" },
      }}
    >
      <Autocomplete
        autoFocus
        fullWidth
        size="small"
        onChange={(e, value) => {
          dispatch({
            type: "CreateChangeOrderForm",
            payload: {
              community: value?.name,
            },
          });
          setCommunities([(value && String(value?.projectId)) || ""]);
        }}
        options={state.communities as CommunityTypes[]}
        filterOptions={filterOptions}
        getOptionLabel={(option) => `${option.name} (${option.projectNumber})`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Community"
            fullWidth
          />
        )}
      />
      {notSold && (
        <>
          <DataGridPro
            sx={{
              minHeight: "10rem",
              width: "100%",
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            slots={{
              loadingOverlay: LoadingGridOverlay,
            }}
            loading={loadingNotSold}
            hideFooter
            disableRowSelectionOnClick
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
              console.log(newRowSelectionModel);
              setSelectedHomes(
                filter(notSold, (obj: any) =>
                  newRowSelectionModel.includes(obj?.id)
                )
              );
            }}
            rowSelectionModel={rowSelectionModel}
            columns={columns}
            rows={notSold}
            rowCount={notSold.length}
            pagination
          />
          <TablePagination
            component="div"
            sx={{
              width: "100%",
            }}
            count={notSold.length} // Total row count comes from the server
            page={paginationModelNotSold.page}
            nextIconButtonProps={{
              disabled:
                paginationModelNotSold.pageSize > notSold.length ? true : false,
            }}
            onPageChange={handleChangePage}
            rowsPerPage={paginationModelNotSold.pageSize}
            onRowsPerPageChange={handleChangePageSize}
          />
        </>
      )}
      <TextField
        margin="dense"
        id="adjustAmmount"
        label="Enter Amount"
        placeholder="0"
        name="adjustAmount"
        type="text" // Keep as text to allow users to input decimals freely
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: formik.values.adjustBy === "dollar" && (
            <InputAdornment position="start">$</InputAdornment>
          ),
          endAdornment: formik.values.adjustBy === "percentage" && (
            <InputAdornment position="end">%</InputAdornment>
          ),
        }}
        value={formik.values.adjustAmount}
        onChange={handleInputChange}
        error={
          (formik.touched.adjustAmount &&
            Boolean(formik.errors.adjustAmount)) ||
          formik.values.adjustAmount > 1000000 ||
          formik.values.adjustAmount < -1000000
        }
        helperText={
          (formik.values.adjustAmount > 1000000 &&
            "The amount must be between -$1M and $1M") ||
          (formik.values.adjustAmount < -1000000 &&
            "The amount must be between -$1M and $1M")
        }
      />
      <TextField
        fullWidth
        id={"column"}
        margin="dense"
        name={`adjustmentReason`}
        label="Change Order Description"
        multiline
        rows={8}
        value={formik.values.adjustmentReason}
        onChange={formik.handleChange}
        error={
          !isEmpty(formik.errors) && Boolean(formik.errors?.adjustmentReason)
        }
        helperText={!isEmpty(formik.errors) && formik.errors?.adjustmentReason}
      />
    </Stack>
  );
};

export default Step1;

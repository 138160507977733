import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import ReusableDialog from "../../components/Dialog";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  AddressTypes,
  BuyerTypes,
  CommunityTypes,
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { useNavigate } from "react-router-dom";
import {
  startNewIntention,
  findCustomerByNameSF,
  getAddress,
  getPlanElevations,
  getPlans,
  newIntention,
  updateKeyword,
  JobHasSubmittedBasePlan,
  JobHasSubmittedIntention,
  GetParentIntentionId,
} from "../../apiCalls";
import {
  debounce,
  isNil,
  isNull,
  isEmpty,
  uniqBy,
  isObject,
  mapValues,
  trim,
} from "lodash";
import useAccessControl from "../../hooks/useAccessControl";
import { Warning } from "@mui/icons-material";
import { filterOptions } from "../../utils/filterOptions";
// import useAccessControl from "../../hooks/useAccessControl";

const CreateChangeOrder = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [alreadySubmittedCODisable, setAlreadySubmittedCoDisable] =
    useState(false);
  const [parentIntentionId, setParentIntentionId] = useState(null);

  /* const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  ); */

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const StartNewIntentionAccess = useAccessControl(
    "Intention",
    "StartNewIntention"
  );

  const sendToEdit = () => {
    setSubmitLoading(true);

    function cleanObject(obj: Record<string, any>): Record<string, any> | null {
      const cleaned: Record<string, any> = {};
      for (const key in obj) {
        if (
          obj[key] === "" ||
          obj[key] === undefined ||
          (typeof obj[key] === "object" &&
            obj[key] !== null &&
            Object.keys(obj[key]).length === 0)
        ) {
          continue; // Skip empty strings, undefined values, and empty objects
        }

        if (typeof obj[key] === "object" && obj[key] !== null) {
          const cleanedSubObject = cleanObject(obj[key]);
          if (cleanedSubObject && Object.keys(cleanedSubObject).length > 0) {
            // Only add non-empty sub-objects
            cleaned[key] = cleanedSubObject;
          }
        } else {
          cleaned[key] = obj[key];
        }
      }
      return Object.keys(cleaned).length > 0 ? cleaned : null;
    }

    const {
      address,
      addressFreeform,
      addressType,
      block,
      buyerType,
      community,
      customerBuyer,
      elevation,
      keyword,
      lot,
      plan,
    } = state.createChangeOrderForm;
    //always required fields:
    let payload: any = {
      keyword: keyword,
      community: {
        projectID: community.projectId,
        projectNumber: community.projectNumber,
      },
      buyer: {
        buyerType: buyerType,
        buyerFirstName: customerBuyer.buyerFirstName
          ? customerBuyer.buyerFirstName
          : customerBuyer,
        salesforceAccountID: customerBuyer.salesforceAccountId,
        salesforceConnectionNumber: customerBuyer.connectionNumber,
        buyerLegalName: customerBuyer.contractBuyerNames,
      },
      address: {
        knownAddress: {
          jobID: address.jobID,
          jobNumber: address.jobNumber,
        },
        lotAndBlock: {
          lot: lot,
          block: block,
        },
        freeformAddress: addressFreeform,
      },
      planId: plan.planId ? plan.planId : address.planId,
      planElevationId: elevation.planElevationID
        ? elevation.planElevationID
        : address.planElevationId,
      planNumberOverride:
        addressType === "knownAddress" &&
        address?.planNumber !== plan?.planNumber,
      planElevationOverride:
        addressType === "knownAddress" &&
        address?.planElevationNumber !== elevation?.planElevationNumber,
    };
    if (buyerType !== "Spec") {
      payload.buyer.buyerLastName = customerBuyer.buyerLastName
        ? customerBuyer.buyerLastName
        : "_";
    } else if (buyerType === "Spec") {
      payload.buyer.buyerLastName = undefined;
      payload.buyer.buyerFirstName = undefined;
    }
    //console.log(payload);
    console.log(cleanObject(payload));

    if (payload.buyer.buyerType === "Spec") {
      payload = {
        ...payload,
        buyer: {
          buyerFirstName: "Spec",
          buyerLastName: "_",
          buyerLegalName: "Spec",
          buyerType: "buyer",
          salesforceAccountID: "Spec",
          salesforceConnectionNumber: "Spec",
        },
      };
    }
    StartNewIntentionAccess &&
      startNewIntention(
        cleanObject(payload) as newIntention,
        (res: any) => {
          updateKeyword(
            {
              intentionId: (res.data as string) || "",
              keyword: keyword,
            },
            (res: any) => {
              console.log(res);
            },
            (err: any) => dispatch(dispatchError(err.response.data))
          );
          navigate(`/edit?intentionId=${res.data}`);
          setSubmitLoading(false);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
          setSubmitLoading(false);

          console.log(err.response.data);
        }
      );

    dispatch({
      type: "ResetChangeOrderPending",
    });

    //navigate(`/edit`);
  };

  const searchSalesForce = debounce((value: string) => {
    findCustomerByNameSF(
      {
        BuyerName: value,
        CommunityName: state.createChangeOrderForm.community.name
          ? state.createChangeOrderForm.community.name
          : "",
      },
      (res: any) => {
        dispatch({
          type: "AvailableBuyerNames",
          payload: res.data,
        });
        setLoadingBuyerNames(false);
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  }, 1000);

  useEffect(() => {
    dispatch({
      type: "AvailableBuyerNames",
      payload: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableCreate = () => {
    function isEmptyOrNull(value: any): boolean {
      const trimedValue = trim(value);

      return trimedValue === "" || trimedValue === null;
    }

    if (
      !isEmptyOrNull(state?.createChangeOrderForm?.customerBuyer) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.community) &&
      state?.createChangeOrderForm?.addressType === "knownAddress" &&
      !isEmptyOrNull(state?.createChangeOrderForm?.address) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.plan) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.plan.planNumber) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.elevation) &&
      !isEmptyOrNull(
        state?.createChangeOrderForm?.elevation?.planElevationNumber
      )
    ) {
      return false;
    }
    if (
      !isEmptyOrNull(state?.createChangeOrderForm?.customerBuyer) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.community) &&
      state?.createChangeOrderForm?.addressType === "lotBlock" &&
      !isEmptyOrNull(state?.createChangeOrderForm?.lot) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.block) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.plan) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.plan.planNumber) &&
      !isEmptyOrNull(
        state?.createChangeOrderForm?.elevation.planElevationNumber
      ) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.elevation)
    ) {
      return false;
    }
    if (
      !isEmptyOrNull(state?.createChangeOrderForm?.customerBuyer) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.community) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.addressFreeform) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.plan) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.plan?.planNumber) &&
      !isEmptyOrNull(
        state?.createChangeOrderForm?.elevation?.planElevationNumber
      ) &&
      !isEmptyOrNull(state?.createChangeOrderForm?.elevation)
    ) {
      return false;
    } else return true;
  };

  return (
    <ReusableDialog
      setIsOpen={(value) => setOpen(value)}
      isOpen={open}
      onChange={() =>
        dispatch({
          type: "ResetCreateChangeOrderForm",
        })
      }
      maxWidth={"md"}
      content={
        <>
          <DialogContent>
            <>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  multiple={false}
                  fullWidth
                  size="medium"
                  value={state.createChangeOrderForm.community || null}
                  onChange={(e, value, reason) => {
                    if (reason === "clear") {
                      setAlreadySubmittedCoDisable(false);
                      dispatch({
                        type: "ResetCreateChangeOrderForm",
                      });
                      return;
                    } else setAlreadySubmittedCoDisable(false);
                    dispatch({
                      type: "CreateChangeOrderForm",
                      payload: {
                        community: value,
                        plan: "",
                        elevation: "",
                        customerBuyer: "",
                        address: "",
                      },
                    });
                    if (value) {
                      setAlreadySubmittedCoDisable(false);

                      getAddress(
                        { projectID: value.projectId },
                        (res: any) => {
                          dispatch({
                            type: "AvailableAddresses",
                            payload: res.data,
                          });
                        },
                        (err: any) =>
                          dispatch(
                            dispatchError({
                              message: err.message,
                              statusText: err.response.statusText,
                              title: err.response.data.title,
                              status: err.response.status,
                              detail: err.response.data.detail,
                              data: err.response.data,
                            })
                          )
                      );
                      GetPlansAccess &&
                        getPlans(
                          {
                            ProjectID: value.projectId,
                            ProjectNumber: value.projectNumber,
                          },
                          (res: any) => {
                            dispatch({
                              type: "AvailablePlans",
                              payload: res.data.sort(
                                (
                                  a: {
                                    planNumber: string;
                                  },
                                  b: {
                                    planNumber: string;
                                  }
                                ) => a.planNumber.localeCompare(b.planNumber)
                              ),
                            });
                          },
                          (err: any) =>
                            dispatch(
                              dispatchError({
                                message: err.message,
                                statusText: err.response.statusText,
                                title: err.response.data.title,
                                status: err.response.status,
                                detail: err.response.data.detail,
                                data: err.response.data,
                              })
                            )
                        );
                    }
                  }}
                  options={state.communities as CommunityTypes[]}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.projectNumber})`
                  }
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Community"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                {
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    freeSolo
                    loading={loadingBuyerName}
                    disabled={isEmpty(state.createChangeOrderForm.community)}
                    size="medium"
                    value={state.createChangeOrderForm.customerBuyer || ""}
                    sx={{
                      ".Mui-error": {
                        color: "green!important",
                        borderColor: "green!important",
                      },
                      ".Mui-error fieldset": {
                        borderColor: "green!important",
                      },
                      ".Mui-error input": {
                        color: "black",
                      },
                    }}
                    onChange={(e, value) => {
                      dispatch({
                        type: "CreateChangeOrderForm",
                        payload: {
                          customerBuyer: value,
                          buyerType: "buyer",
                        },
                      });
                    }}
                    onInputChange={(e, value) => {
                      console.log(value);
                      console.log(value.trim().toLowerCase() === "spec");

                      if (value.trim().toLowerCase() === "spec") {
                        dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            salesforceAccountId: "Spec",
                            connectionId: "Spec",
                            connectionNumber: "Spec",
                            buyerFirstName: "Spec",
                            buyerLastName: "",
                            customerBuyer: "Spec",
                            buyerType: "Spec",
                          },
                        });
                      } else if (!isNil(value) && value.length >= 3) {
                        dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            customerBuyer: value,
                            buyerType: "buyer",
                          },
                        });
                        if (e && e?.type !== "click") {
                          setLoadingBuyerNames(true);
                          FindCustomerByNameFromSFAccess &&
                            searchSalesForce(value);
                        }
                      }
                    }}
                    options={state.availableBuyerNames as BuyerTypes[]}
                    getOptionLabel={(option: any) => {
                      if (option.contractBuyerNames === "Spec") {
                        return `${option.contractBuyerNames}`;
                      } else if (
                        option.contractBuyerNames &&
                        option.connectionNumber
                      ) {
                        return `${option.contractBuyerNames} -  ${option.connectionNumber}`;
                      } else return option;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={
                          isObject(
                            state?.createChangeOrderForm?.customerBuyer
                          ) &&
                          state?.createChangeOrderForm?.customerBuyer
                            ?.connectionNumber
                        }
                        helperText={
                          isObject(
                            state?.createChangeOrderForm?.customerBuyer
                          ) &&
                          state?.createChangeOrderForm?.customerBuyer
                            ?.connectionNumber &&
                          "Buyer in Salesforce"
                        }
                        label="Buyer"
                        fullWidth
                      />
                    )}
                  />
                }
              </Box>
              <Box gap={2} marginY={(theme) => theme.spacing(2)}>
                {state.createChangeOrderForm.addressType === "knownAddress" && (
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    size="medium"
                    onFocus={async () => {
                      if (
                        state.createChangeOrderForm.customerBuyer === "Spec"
                      ) {
                        await dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            customerBuyer: state.availableBuyerNames[0],
                            buyerType: "buyer",
                          },
                        });
                      }
                    }}
                    sx={{
                      ".Mui-error": {
                        color: "green!important",
                        borderColor: "green!important",
                      },
                      ".Mui-error fieldset": {
                        borderColor: "green!important",
                      },
                      ".Mui-error input": {
                        color: "black",
                      },
                    }}
                    defaultValue={""}
                    disabled={isEmpty(state.createChangeOrderForm.community)}
                    value={
                      state.createChangeOrderForm.address ||
                      state.createChangeOrderForm.addressFreeform ||
                      null
                    }
                    onChange={(e, value) => {
                      if (isNull(value)) {
                        setAlreadySubmittedCoDisable(false);

                        dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            address: "",
                            plan: {
                              planNumber: "",
                              planElevationNumber: "",
                            },
                            elevation: {
                              planNumber: "",
                              planElevationNumber: "",
                              abbreviation: "",
                            },
                          },
                        });
                      } else if (value) {
                        console.log("Address", value);
                        if (value.planId && value.planNumber) {
                          GetPlanElevationsAccess &&
                            getPlanElevations(
                              {
                                projectID:
                                  state.createChangeOrderForm.community
                                    .projectId,
                                projectNumber:
                                  state.createChangeOrderForm.community
                                    .projectNumber,
                                planId: value.planId,

                                planNumber: value.planNumber,
                              },
                              (res: any) => {
                                dispatch({
                                  type: "AvailableElevations",
                                  payload: res.data.sort(
                                    (
                                      a: {
                                        planElevationNumber: string;
                                      },
                                      b: {
                                        planElevationNumber: string;
                                      }
                                    ) =>
                                      a.planElevationNumber.localeCompare(
                                        b.planElevationNumber
                                      )
                                  ),
                                });
                              },
                              (err: any) =>
                                dispatch(
                                  dispatchError({
                                    message: err.message,
                                    statusText: err.response.statusText,
                                    title: err.response.data.title,
                                    status: err.response.status,
                                    detail: err.response.data.detail,
                                    data: err.response.data,
                                  })
                                )
                            );
                        }
                        if (value.jobID) {
                          JobHasSubmittedBasePlan(
                            { jobId: value.jobID },
                            (res: any) =>
                              res &&
                              res.data &&
                              GetParentIntentionId(
                                { jobId: value.jobID },
                                (res: any) => setParentIntentionId(res.data),
                                (err: any) =>
                                  dispatch(dispatchError(err.response.data))
                              ),
                            (err: any) =>
                              dispatch(dispatchError(err.response.data))
                          );
                          JobHasSubmittedIntention(
                            { jobId: value.jobID },
                            (res: any) =>
                              res &&
                              (setAlreadySubmittedCoDisable(res.data),
                              res.data &&
                                GetParentIntentionId(
                                  { jobId: value.jobID },
                                  (res: any) => setParentIntentionId(res.data),
                                  (err: any) =>
                                    dispatch(dispatchError(err.response.data))
                                )),
                            (err: any) =>
                              dispatch(dispatchError(err.response.data))
                          );
                        }
                        GetPlansAccess &&
                          getPlans(
                            {
                              ProjectID:
                                state.createChangeOrderForm.community.projectId,
                              projectNumber:
                                state.createChangeOrderForm.community
                                  .projectNumber,
                            },
                            (res: any) => {
                              dispatch({
                                type: "AvailablePlans",
                                payload: res.data.sort(
                                  (
                                    a: {
                                      planNumber: string;
                                    },
                                    b: {
                                      planNumber: string;
                                    }
                                  ) => a.planNumber.localeCompare(b.planNumber)
                                ),
                              });
                              const {
                                planNumber,
                                planElevationNumber,
                                abbreviation,
                                description,
                              } = value;
                              const updatedObject = mapValues(value, (value) =>
                                value === null ? "" : value
                              );

                              dispatch({
                                type: "CreateChangeOrderForm",
                                payload: {
                                  lot: "",
                                  block: "",
                                  addressFreeform: "",
                                  address: updatedObject,
                                  plan: {
                                    planNumber: isNull(planNumber)
                                      ? ""
                                      : planNumber,
                                    planElevationNumber: isNull(
                                      planElevationNumber
                                    )
                                      ? ""
                                      : planElevationNumber,
                                    description,
                                  },
                                  elevation: {
                                    planNumber: isNull(planNumber)
                                      ? ""
                                      : planNumber,
                                    planElevationNumber: isNull(
                                      planElevationNumber
                                    )
                                      ? ""
                                      : planElevationNumber,
                                    abbreviation,
                                  },
                                },
                              });
                            },
                            (err: any) =>
                              dispatch(
                                dispatchError({
                                  message: err.message,
                                  statusText: err.response.statusText,
                                  title: err.response.data.title,
                                  status: err.response.status,
                                  detail: err.response.data.detail,
                                  data: err.response.data,
                                })
                              )
                          );
                      }
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "clear") {
                        setAlreadySubmittedCoDisable(false);

                        dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            lot: "",
                            block: "",
                            addressFreeform: "TBD / Preliminary",
                            address: "",
                            plan: "",
                            elevation: "",
                          },
                        });
                      }
                    }}
                    options={(state.availableAddresses as AddressTypes[]) || ""}
                    getOptionLabel={(option: any) => {
                      if (typeof option === "string") return option;
                      if (option.address === "TBD / Preliminary")
                        return option.address;
                      return option && option.address !== null
                        ? `${option.address}  ${
                            !isNull(option.planNumber) &&
                            !isNull(option.planElevationNumber) &&
                            !isEmpty(option.planNumber) &&
                            !isEmpty(option.planElevationNumber)
                              ? `(${option.planNumber} / ${option.planElevationNumber})`
                              : ""
                          } | ${option.projectNumber}-${option.jobNumber}`
                        : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Address"
                        fullWidth
                        error={isObject(state?.createChangeOrderForm?.address)}
                        helperText={
                          isObject(state?.createChangeOrderForm?.address) &&
                          "Address in BRIX"
                        }
                      />
                    )}
                  />
                )}
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  defaultValue={""}
                  onFocus={() => {
                    if (state.createChangeOrderForm.customerBuyer === "Spec") {
                      console.log(state.availableBuyerNames[0]);
                      dispatch({
                        type: "CreateChangeOrderForm",
                        payload: {
                          customerBuyer: state.availableBuyerNames[0],
                          buyerType: "buyer",
                        },
                      });
                    }
                  }}
                  disabled={
                    isEmpty(state.createChangeOrderForm.community) ||
                    alreadySubmittedCODisable
                  }
                  fullWidth
                  getOptionLabel={(option) => {
                    return `${option.planNumber}${
                      option.description ? ` - ${option.description}` : ""
                    }`;
                  }}
                  multiple={false}
                  onChange={(e, value) => {
                    dispatch({
                      type: "CreateChangeOrderForm",
                      payload: {
                        plan: value,
                        elevation: "",
                      },
                    });
                    if (!isNull(value)) {
                      if (value.projectID) {
                        const { projectID, projectNumber, planId, planNumber } =
                          value;
                        GetPlanElevationsAccess &&
                          getPlanElevations(
                            { projectID, projectNumber, planId, planNumber },
                            (res: any) => {
                              dispatch({
                                type: "AvailableElevations",
                                payload: res.data.sort(
                                  (
                                    a: {
                                      planElevationNumber: string;
                                    },
                                    b: {
                                      planElevationNumber: string;
                                    }
                                  ) =>
                                    a.planElevationNumber.localeCompare(
                                      b.planElevationNumber
                                    )
                                ),
                              });
                            },
                            (err: any) =>
                              dispatch(
                                dispatchError({
                                  message: err.message,
                                  statusText: err.response.statusText,
                                  title: err.response.data.title,
                                  status: err.response.status,
                                  detail: err.response.data.detail,
                                  data: err.response.data,
                                })
                              )
                          );
                      }
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    if (!isNil(e)) {
                      if (e.type !== "click") {
                        dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            planOverride: value,
                          },
                        });
                      }
                    }
                  }}
                  options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
                    return obj.description;
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        state.createChangeOrderForm.addressFreeform === "" &&
                        !isEmpty(
                          state?.createChangeOrderForm?.address?.planNumber
                        ) &&
                        state?.createChangeOrderForm?.address?.planNumber !==
                          state?.createChangeOrderForm?.plan?.planNumber &&
                        true
                      }
                      helperText={
                        state.createChangeOrderForm.addressFreeform === "" &&
                        !isEmpty(
                          state?.createChangeOrderForm?.address?.planNumber
                        ) &&
                        state?.createChangeOrderForm?.address?.planNumber !==
                          state?.createChangeOrderForm?.plan?.planNumber &&
                        "*Address plan has been overriden"
                      }
                      variant="outlined"
                      label="Plan"
                      fullWidth
                    />
                  )}
                  size="medium"
                  value={state.createChangeOrderForm.plan || null}
                />
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  multiple={false}
                  fullWidth
                  size="medium"
                  defaultValue={""}
                  disabled={
                    isEmpty(state.createChangeOrderForm.plan) ||
                    alreadySubmittedCODisable
                  }
                  value={state.createChangeOrderForm.elevation || null}
                  onInputChange={(e, value) => {
                    if (!isNil(e)) {
                      if (e.type !== "click") {
                        dispatch({
                          type: "CreateChangeOrderForm",
                          payload: {
                            planElevationOverride: value,
                          },
                        });
                      }
                    }
                    if (state.createChangeOrderForm.customerBuyer === "Spec") {
                      console.log(state.availableBuyerNames[0]);
                      dispatch({
                        type: "CreateChangeOrderForm",
                        payload: {
                          customerBuyer: state.availableBuyerNames[0],
                          buyerType: "buyer",
                        },
                      });
                    }
                  }}
                  onChange={(e, value) => {
                    if (isNil(value)) {
                      console.log(value);
                      dispatch({
                        type: "CreateChangeOrderForm",
                        payload: {
                          elevation: {
                            planElevationNumber: "",
                          },
                        },
                      });
                    } else
                      dispatch({
                        type: "CreateChangeOrderForm",
                        payload: {
                          elevation: value,
                        },
                      });
                  }}
                  options={state.availableElevations as ElevationTypes[]}
                  getOptionLabel={(option) => `${option.planElevationNumber}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        state.createChangeOrderForm.addressFreeform === "" &&
                        !isEmpty(
                          state?.createChangeOrderForm?.address
                            ?.planElevationNumber
                        ) &&
                        state?.createChangeOrderForm?.address
                          ?.planElevationNumber !==
                          state?.createChangeOrderForm?.elevation
                            ?.planElevationNumber &&
                        true
                      }
                      helperText={
                        state.createChangeOrderForm.addressFreeform === "" &&
                        !isEmpty(
                          state?.createChangeOrderForm?.address
                            ?.planElevationNumber
                        ) &&
                        state?.createChangeOrderForm?.address
                          ?.planElevationNumber !==
                          state?.createChangeOrderForm?.elevation
                            ?.planElevationNumber &&
                        "*Address elevation has been overriden"
                      }
                      variant="outlined"
                      label="Elevation"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                <TextField
                  fullWidth
                  onChange={(e) =>
                    dispatch({
                      type: "CreateChangeOrderForm",
                      payload: {
                        keyword: e.target.value,
                      },
                    })
                  }
                  value={state.createChangeOrderForm.keyword}
                  label="Keyword (25 Character Limit)"
                  variant="outlined"
                  placeholder="Keywords (25 Character Limit)"
                  inputProps={{
                    maxLength: 25,
                  }}
                  helperText={`${
                    state.createChangeOrderForm.keyword
                      ? state.createChangeOrderForm.keyword.length
                      : 0
                  }/25 Characters`}
                />
              </Box>
              <Box>
                {alreadySubmittedCODisable && (
                  <Alert
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    action={
                      <Button
                        sx={{
                          minWidth: "12rem",
                        }}
                        onClick={() =>
                          navigate(
                            `/job-summary?addressid=${parentIntentionId}`
                          )
                        }
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        Go To Job Summary
                      </Button>
                    }
                    icon={<Warning fontSize="inherit" />}
                    severity="error"
                  >
                    {state.createChangeOrderForm.address.address} already has a
                    Change Order submitted for this address. Please go to the
                    Job Summary to create subsequent Change Orders.
                  </Alert>
                )}
              </Box>{" "}
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setAlreadySubmittedCoDisable(false);
                dispatch({
                  type: "ResetCreateChangeOrderForm",
                });
              }}
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>
            <Button
              disabled={
                disableCreate() || submitLoading || alreadySubmittedCODisable
              }
              onClick={() => sendToEdit()}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </>
      }
      buttonText="Create Initial Change Order"
      title="Create Initial Change Order"
    />
  );
};

export default CreateChangeOrder;

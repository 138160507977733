import { useEffect, useContext, useState } from "react";
import { dispatchError } from "../../common/fx";
import { UIContext, UIState } from "../../providers/UIProvider";
import { useParams, Link } from "react-router-dom";
import { GetReport, GetReports } from "../../apiCalls";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Skeleton,
  ThemeProvider 
} from "@mui/material";
import {
  GridColumnVisibilityModel,
  DataGridPro,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import "./DynamicReportDetail.scss";
import { mainTheme } from "../../styles/MainTheme";

export default function DynamicReportDetail(props:any) {
  const { reportName } = useParams();
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [reportInfo, setReportInfo] = useState<any>(null);
  const [reportData, setReportData] = useState<any>(null);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [gridColumns, setGridColumns] = useState<any[] | null>(null);
  const [gridRows, setGridRows] = useState<any[] | null>(null);
  const apiRefGrid = useGridApiRef();
  const navigate = useNavigate();

  useEffect(() => {
    if(reportName) {
      GetReport(
        reportName,
        (response:any) => {
          response.data ?
            setReportData(JSON.parse(response.data)) :
            setReportData([]);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );
      GetReports(
        (response: any) => {
          const newReportInfo = response.data.find((report:any) => report.reportName === reportName);
          setReportInfo(newReportInfo);
          dispatch({
            type: "Navbar",
            payload: { title: `Dynamic Reports :: ${newReportInfo.displayName}` },
          });
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );
    }
  }, [reportName]);

  useEffect(() => {
    if(reportData) {
      if(reportData.length > 0) {
        console.log(reportData);
        const columnNames = Object.keys(reportData[0]);
        const columns:any[] = [];
        const newVisibilityModel = { ...visibilityModel };
        columnNames.forEach((column:string) => {
            switch(column) {
              case "AddressId":
                newVisibilityModel.AddressId = false;
                break;
              case "JobKey":
                columns.push({
                  flex: 1,
                  field: column,
                  headerName: column,
                  editable: false,
                  renderCell: (params: GridRenderCellParams<any, Date>) => (
                    <Link to={`../job-summary?addressid=${params.row.AddressId}`} target="_blank">{params.row.JobKey}</Link>
                  )
                });
                break;
              default:
                columns.push({
                  flex: 1,
                  field: column,
                  headerName: column,
                  editable: false,
                  type: "string"
                });
            }
          });
        setGridColumns(columns);
        setVisibilityModel(newVisibilityModel);
        const rows:any[] = reportData
          .map((row:any, rIx:number) => (
            {
              id: rIx,
              ...row
            }
          ));
        setGridRows(rows);
      } else {
        setGridColumns([]);
        setGridRows([]);
      }
    }
  }, [reportData]);

  const customToolBar = () => {
    return (
      <GridToolbarContainer sx={{ padding: ".5rem 1rem"}}>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          variant="outlined"
        />
      </GridToolbarContainer>
    );
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          my: mainTheme.spacing(2),
        }}
        component="main"
        maxWidth={false}
      >
        <CssBaseline />
        { reportData && reportInfo &&
          <div className="report-info">
            <h1 className="report-title">{reportInfo.displayName}</h1>
            <p className="report-desc">{reportInfo.description}</p>
          </div>
        }
        { reportData === null && 
          <div className="report-info">
            <Skeleton variant="text" height={48} width={300} sx={{ marginTop: '21px'}} />
            <Skeleton variant="text" height={21} width={400} sx={{ marginTop: '8px', marginBottom: '24px' }} />
          </div>
        }
        { gridColumns && gridRows &&
          gridColumns.length > 0 && gridRows.length > 0 &&
          <DataGridPro
            apiRef={apiRefGrid}
            columnHeaderHeight={46}
            columns={gridColumns}
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
            }}
            pageSizeOptions={[50, 100, 200]}
            pagination
            rowCount={gridRows.length}
            rowHeight={46}
            rows={gridRows}
            columnVisibilityModel={visibilityModel}
            slots={{ toolbar: customToolBar }}
          />
        }
        {
          gridColumns !== null && gridRows !== null &&
          gridColumns.length === 0 && gridRows.length === 0 &&
          <Box
            sx={{ 
              border: `1px solid ${mainTheme.palette.primary.main}`,
              borderRadius: ".5rem",
              fontSize: "1.75rem",
              fontWeight: "semibold",
              padding: "2rem 0",
              textAlign: "center"
            }}
          >
            There's no data for this report at this moment.
          </Box>
        }
        <Box sx={{ marginTop: "40px" }}>
          <Button
            onClick={() => { navigate("../reports") }}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  )
};
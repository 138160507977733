import { useState, useContext, useEffect } from "react";
import { dispatchError, removeFirstRowIfEmpty } from "../../common/fx";
import {
  Box,
  Button,
  DialogContent,
  DialogActions
} from "@mui/material";
import {
  DataGridPro,
  useGridApiRef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { DeleteWizard } from "../../assets/icons/hhIcons";
import ReusableDialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import {
  HighlandCategoryOption,
  OptionTypes,
  UIState,
  UIContext
} from "../../providers/UIProvider";
import { allChangeOrderOptions } from "../../apiCalls";


interface DeleteOptionsProps {
  highlandCategory: HighlandCategoryOption
}

const DeleteOptions = (props: DeleteOptionsProps) => {
  const [open, setOpen] = useState(false);
  const [originalOptions, setOriginalOptions] = useState<any[]>([]);
  const [deleteOptionsAvailable, setDeleteOptionsAvailable] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridRows, setGridRows] = useState<any[]>([]);
  const apiRefGrid = useGridApiRef();
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  useEffect(() => {
    if(!state.changeOrder) return;
    const params = {
      JobId: state.changeOrder.address.jobID,
      highlandCategoryType: props.highlandCategory.key,
      statuses: ["submitted"]
    };

    allChangeOrderOptions(
      params,
      (res: any) => {
        setOriginalOptions(res.data.value);
      },
      (err: any) => {
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!originalOptions || originalOptions.length === 0) return;

    setDeleteOptionsAvailable(originalOptions
      .filter((option: any) =>
        option.cancelsOptionId === null && // available options shouldn't be cancelling ones
        option.category !== "Plan and Elevation" && // shouldn't be Plan & Elevation ones
        option.category !== "Formatting Separator" && // shouldn't be a Formatting Separator line
        // and shouldn't be part of another that's already cancelling them:
        !state.changeOrder.pendingItems.some((item: OptionTypes) => {
          return item.cancelsOptionId === option.id;
        })
      )
    );
  }, [originalOptions, state.changeOrder.pendingItems])

  useEffect(() => {
    const newRows = buildRows();
    setGridRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOptionsAvailable, selectedOptions]);

  useEffect(() => {
    setGridColumns(buildColumns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRows]);

  const buildRows = () => {
    return deleteOptionsAvailable.map((option:any) => {
      return {
        isSelected:
          selectedOptions !== null && selectedOptions.find((selectedId: string) => selectedId === option.id),
        ...option
      };
    });
  };

  const buildColumns = () => {
    return [
      {
        flex: 2,
        field: "changeOrderNumber",
        headerName: "CO Number",
        editable: false,
        type: "string",
      },
      {
        flex: 2,
        field: "optionCode",
        headerName: "Option Code",
        editable: false,
        type: "string",
      },
      {
        flex: 4,
        field: "description",
        headerName: "Description",
        editable: false,
        type: "string",
      },
      {
        flex: 1,
        field: "quantity",
        headerName: "Qty",
        editable: false,
        type: "number",
      },
      {
        flex: 2,
        field: "unitPrice",
        headerName: "Unit Price",
        editable: false,
        valueFormatter: ((params: any) => `$ ${parseFloat(params.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`),
        type: "number"
      },
      {
        flex: 2,
        field: "totalPrice",
        headerName: "Total Price",
        editable: false,
        valueGetter: ((params:any) => params.row.unitPrice * params.row.quantity),
        valueFormatter: ((params: any) => `$ ${parseFloat(params.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`),
        type: "number"
      }
    ];
  };

  const handleRowSelection = ((newRowSelection:GridRowSelectionModel) => {
    setSelectedOptions(newRowSelection);
  });

  const loadselectedOptions = () => {
    // starting point to start adding rows:
    const newPendingItems = removeFirstRowIfEmpty([...state.changeOrder.pendingItems], props.highlandCategory);
    let baseRowIx:number = 0;
    let maxSortOrder = 0;
    newPendingItems.forEach((row: OptionTypes, rowIx: number) => {
      if (
        row.highlandCategory.key === props.highlandCategory.key &&
        row.sortOrder > maxSortOrder
      ) {
        maxSortOrder = row.sortOrder;
        baseRowIx = rowIx;
      }
    });
    let newSortOrder = maxSortOrder;

    // new rows to be added into the Grid:
    selectedOptions.forEach((optionId:string) => {
      const optionToAdd = deleteOptionsAvailable.find((option:any) => option.id === optionId);
      const newId = Math.random().toString(16).slice(2);
      newSortOrder++;
      baseRowIx++;
      const newRow = {
        id: newId,
        category: optionToAdd.category,
        forcedOption: false,
        highlandCategory: props.highlandCategory,
        manualPrice: false,
        option: optionToAdd.category,
        optionCategoryID: 1,
        optionID: 1,
        optionCode: optionToAdd.optionCode,
        description: optionToAdd.description,
        notes: optionToAdd.notes,
        quantity: -optionToAdd.quantity,
        unitPrice: optionToAdd.unitPrice,
        isNew: true,
        totalPrice: -optionToAdd.salesPrice,
        salesPriceControlID: optionToAdd.salesPriceControlID,
        sortOrder: newSortOrder,
        cancelsOptionId: optionToAdd.id
      };
      newPendingItems.splice(baseRowIx, 0, newRow);
    });

    dispatch({
      type: "ChangeOrderPending",
      payload: newPendingItems,
      source: "Delete Options modal",
    });

    setSelectedOptions([]);
    setOpen(false);
  };

  // don't show the button if there aren't available options for deletion on this category:
  if(deleteOptionsAvailable.length === 0)
    return null;

  return (
    <>
      <ReusableDialog
        buttonVariant="outlined"
        buttonFullWidth={false}
        disabled={deleteOptionsAvailable?.length === 0}
        setIsOpen={(value) => setOpen(value)}
        icon={<DeleteWizard />}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent>
              <>
                <Box marginY={(theme) => theme.spacing(2)}>
                  {gridColumns && gridRows ? (
                    <DataGridPro
                      apiRef={apiRefGrid}
                      checkboxSelection
                      columnHeaderHeight={46}
                      columns={gridColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      onRowSelectionModelChange={handleRowSelection}
                      rowSelectionModel={selectedOptions}
                      pageSizeOptions={[5, 10, 20]}
                      pagination
                      rowCount={gridRows.length}
                      rowHeight={46}
                      rows={gridRows}
                    />
                  ) : (
                    <Loading />
                  )}
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setSelectedOptions([]);
                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
              >
                Exit
              </Button>
              <Button
                onClick={() => {
                  loadselectedOptions();
                }}
                variant="contained"
                color="primary"
                disabled={selectedOptions === null || selectedOptions.length === 0}
              >
                Add for Deletion
              </Button>
            </DialogActions>
          </>
        }
        title={`Delete Submitted Options in ${props.highlandCategory.label} Category`}
        toolTipTitle="Delete options wizard"
      />
    </>
  );
};

export default DeleteOptions;

import { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for React Quill
//@ts-ignore
import ReusableDialog from "../../components/Dialog";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  ListItemText,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Stack } from "@mui/system";
import { AddSystemMessage } from "../../apiCalls";
import { isEmpty } from "lodash";
import { UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";

type Props = {
  setRows?: any;
  rows?: any;
  open: boolean;
  fetchSystemMessages: any;
  setOpen: (value: boolean) => void;
  editObject?: any;
};

const NotificationModal = ({
  open,
  setOpen,
  setRows,
  rows,
  fetchSystemMessages,
}: Props) => {
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  const [systemMessage, setSystemMessage] = useState<{
    type: "KnownIssue" | "NewFeature" | "SystemMaintenance" | "";
    title: string;
    message: string;
    expiresAt: any;
    id: string | null;
  }>({
    type: "",
    title: "",
    message: "",
    expiresAt: moment(),
    id: null,
  });

  const handleConfirm = () => {
    AddSystemMessage(
      {
        type: systemMessage.type,
        title: systemMessage.title,
        message: systemMessage.message,
        expires: systemMessage.expiresAt,
      },
      (res: any) => (
        fetchSystemMessages(),
        setSystemMessage({
          type: "",
          title: "",
          message: "",
          expiresAt: moment(),
          id: null,
        })
      ),
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  // Custom modules and toolbar configuration for React Quill
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }], // Dropdown with defaults from theme
      [{ align: [] }],
      ["link"],
      ["clean"], // Remove formatting button
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <ReusableDialog
      setIsOpen={setOpen}
      isOpen={open}
      maxWidth={"md"}
      buttonText="Create Notification"
      content={
        <>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack
                padding={(theme) => theme.spacing(2)}
                direction={"column"}
                height={"25rem"}
                spacing={2}
              >
                <Box>
                  <Autocomplete
                    fullWidth
                    size="small"
                    onChange={async (events, value) => {
                      setSystemMessage({
                        ...systemMessage,
                        type: value as
                          | "KnownIssue"
                          | "NewFeature"
                          | "SystemMaintenance",
                      });
                    }}
                    options={["KnownIssue", "NewFeature", "SystemMaintenance"]}
                    getOptionLabel={(option) => `${option}`}
                    value={systemMessage.type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Notification Type"
                        fullWidth
                      />
                    )}
                    renderOption={(props, item) => (
                      <li {...props}>
                        <ListItemText>{`${item}`}</ListItemText>
                      </li>
                    )}
                  />
                </Box>
                <Box>
                  <DateTimePicker
                    sx={{
                      width: "100%",
                    }}
                    minDate={moment()}
                    label="Expiration Date"
                    value={systemMessage.expiresAt}
                    onChange={(newValue) =>
                      setSystemMessage({
                        ...systemMessage,
                        expiresAt: newValue,
                      })
                    }
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    label={"Title"}
                    value={systemMessage.title}
                    onChange={(e: any) =>
                      setSystemMessage({
                        ...systemMessage,
                        title: e.target.value,
                      })
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box>
                  <ReactQuill
                    theme="snow"
                    value={systemMessage.message}
                    onChange={(value: string) =>
                      setSystemMessage({ ...systemMessage, message: value })
                    }
                    modules={modules}
                    style={{ height: "300px" }}
                  />
                </Box>
              </Stack>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setOpen(false);
                setSystemMessage({
                  type: "",
                  title: "",
                  message: "",
                  expiresAt: moment(),
                  id: null,
                });
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleConfirm();
                setOpen(false);
              }}
              disabled={
                isEmpty(systemMessage.type.trim()) ||
                isEmpty(systemMessage.message.trim()) ||
                isEmpty(systemMessage.title.trim()) ||
                isEmpty(systemMessage.expiresAt)
              }
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </>
      }
      title="Create System Notification"
    />
  );
};

export default NotificationModal;

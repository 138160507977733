import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TablePagination,
  Typography,
} from "@mui/material";
import { DataGridPro, GridFilterModel } from "@mui/x-data-grid-pro";
import React from "react";
import { UIContext, UIState } from "../../providers/UIProvider";
import { jobsApiFiltered } from "../../apiCalls";
import LoadingGridOverlay from "../../components/Loading/LoadingGridOverlay";
import { useEffect } from "react";
import { dispatchError } from "../../common/fx";
import useAccessControl from "../../hooks/useAccessControl";
import axios, { CancelTokenSource } from "axios";
import { numberWithCommas } from "../../utils/formatMoney";

type Props = {
  columns: any;
  searchText: string;
  communities: string[];
};

const AltViewGrid = ({ columns, searchText, communities }: Props) => {
  const jobsApiFilteredApiAccess = useAccessControl("Job", "Get");

  const [, dispatch] = React.useContext<UIState | any>(UIContext);

  //sold states

  const [loadingSold, setLoadingSold] = React.useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginationModelSold, setPaginationModelSold] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const [filtSold, setFiltSold] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  //pending states

  const [loadingPending, setLoadingPending] = React.useState<boolean>(true);
  const [notSold, setNotSold] = React.useState<any>([
    {
      data: [],
      totalRecordCount: 0,
    },
  ]);
  const [sold, setSold] = React.useState<any>([
    {
      data: [],
      totalRecordCount: 0,
    },
  ]);

  const [pending, setPending] = React.useState<any>([
    {
      data: [],
      totalRecordCount: 0,
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginationModelPending, setPaginationModelPending] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const [filtPending, setFiltPending] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  //not sold states
  const [loadingNotSold, setLoadingNotSold] = React.useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginationModelNotSold, setPaginationModelNotSold] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const [filtNotSold, setFiltNotSold] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  const [cancelTokenSourcePending, setCancelTokenSourcePending] =
    React.useState<CancelTokenSource | null>(null);

  const [cancelTokenSourceSold, setCancelTokenSourceSold] =
    React.useState<CancelTokenSource | null>(null);

  const [cancelTokenSourceNotSold, setCancelTokenSourceNotSold] =
    React.useState<CancelTokenSource | null>(null);

  const fetchAddresses = (
    paginationModel: {
      page: number;
      pageSize: number;
    },
    jobType: string,
    setArr: any,
    setLoading: any,
    cancelTokenSource: any,
    setCancelTokenSource: any
  ) => {
    setLoading(true);
    // Cancel the previous request if exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled due to new request.");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);
    jobsApiFiltered(
      {
        searchText: searchText,
        communities: communities.map((obj: any) => String(obj.projectId)),
        jobType: jobType,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      },

      (res: any) => {
        setLoading(false);

        setArr({
          totalRecordCount: res.data.totalRecordCount,
          data: res.data.summaries,
        });
      },
      (err: any) => {
        if (err.code === "ERR_CANCELED") {
          console.log(err);
        } else {
          dispatch(dispatchError(err.response.data));
        }
      },
      newCancelTokenSource
    );
  };

  useEffect(() => {
    jobsApiFilteredApiAccess &&
      fetchAddresses(
        paginationModelPending,
        "InitialPending",
        setPending,
        setLoadingPending,
        cancelTokenSourcePending,
        setCancelTokenSourcePending
      );
    return () => {
      // Cleanup function to cancel the request when component unmounts or dependencies change
      if (cancelTokenSourcePending) {
        cancelTokenSourcePending.cancel(
          "Operation canceled due to component unmounting or dependencies change."
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationModelPending,
    filtPending,
    searchText,
    communities,
    jobsApiFilteredApiAccess,
  ]);

  useEffect(() => {
    fetchAddresses(
      paginationModelSold,
      "sold",
      setSold,
      setLoadingSold,
      cancelTokenSourceSold,
      setCancelTokenSourceSold
    );

    return () => {
      // Cleanup function to cancel the request when component unmounts or dependencies change
      if (cancelTokenSourceSold) {
        cancelTokenSourceSold.cancel(
          "Operation canceled due to component unmounting or dependencies change."
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModelSold, filtSold, searchText, communities]);

  useEffect(() => {
    fetchAddresses(
      paginationModelNotSold,
      "NotSold",
      setNotSold,
      setLoadingNotSold,
      cancelTokenSourceNotSold,
      setCancelTokenSourceNotSold
    );

    return () => {
      // Cleanup function to cancel the request when component unmounts or dependencies change
      if (cancelTokenSourceNotSold) {
        cancelTokenSourceNotSold.cancel(
          "Operation canceled due to component unmounting or dependencies change."
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModelNotSold, filtNotSold, searchText, communities]);

  return (
    <>
      <Accordion
        data-testid={"alt-view-grid"}
        className="highland-category--accordion"
        key={`sold-category`}
      >
        <AccordionSummary
          className="highland--accordion--summary"
          expandIcon={<ExpandMore />}
          aria-controls={`sold-category`}
          id={`sold-category`}
        >
          <Typography>
            Sold - {numberWithCommas(sold.totalRecordCount)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGridPro
            sx={{
              marginTop: (theme) => theme.spacing(2),
              height: "100%",
              minHeight: "20rem",
            }}
            pagination
            filterModel={filtSold as GridFilterModel | undefined}
            onFilterModelChange={(newFilterModel) =>
              setFiltSold(newFilterModel as any)
            }
            paginationMode="server"
            disableRowSelectionOnClick
            hideFooter
            columns={columns}
            getRowId={(obj: { id: string }) => obj?.id}
            rows={sold.data ?? []}
            rowCount={sold.totalRecordCount}
            loading={loadingSold}
            slots={{
              loadingOverlay: LoadingGridOverlay,
            }}
          />
          <TablePagination
            component="div"
            count={sold?.totalRecordCount}
            page={paginationModelSold.page}
            onPageChange={(e, newpage) =>
              setPaginationModelSold({
                ...paginationModelSold,
                page: newpage,
              })
            }
            rowsPerPage={paginationModelSold.pageSize}
            onRowsPerPageChange={(e) =>
              setPaginationModelSold({
                ...paginationModelSold,
                pageSize: parseInt(e.target.value, 10),
              })
            }
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        data-testid={"alt-view-grid"}
        className="highland-category--accordion"
        key={`not-sold-category`}
      >
        <AccordionSummary
          className="highland--accordion--summary"
          expandIcon={<ExpandMore />}
          aria-controls={`not-sold-category`}
          id={`not-sold-category`}
        >
          <Typography>
            Spec - {numberWithCommas(notSold.totalRecordCount)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGridPro
            sx={{
              marginTop: (theme) => theme.spacing(2),
              height: "100%",
              minHeight: "20rem",
            }}
            pagination
            filterModel={filtNotSold as GridFilterModel | undefined}
            onFilterModelChange={(newFilterModel) =>
              setFiltNotSold(newFilterModel as any)
            }
            paginationMode="server"
            disableRowSelectionOnClick
            hideFooter
            columns={columns}
            rows={notSold.data ?? []}
            getRowId={(obj: { id: string }) => obj?.id}
            rowCount={notSold.totalRecordCount}
            loading={loadingNotSold}
            slots={{
              loadingOverlay: LoadingGridOverlay,
            }}
          />
          <TablePagination
            component="div"
            count={notSold?.totalRecordCount}
            page={paginationModelNotSold.page}
            onPageChange={(e, newpage) =>
              setPaginationModelNotSold({
                ...paginationModelNotSold,
                page: newpage,
              })
            }
            rowsPerPage={paginationModelNotSold.pageSize}
            onRowsPerPageChange={(e) =>
              setPaginationModelNotSold({
                ...paginationModelNotSold,
                pageSize: parseInt(e.target.value, 10),
              })
            }
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        data-testid={"alt-view-grid"}
        className="highland-category--accordion"
        key={`pending-category`}
      >
        <AccordionSummary
          className="highland--accordion--summary"
          expandIcon={<ExpandMore />}
          aria-controls={`initialpending-category`}
          id={`pending-category`}
        >
          <Typography>
            Initial Pending - {numberWithCommas(pending.totalRecordCount)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGridPro
            sx={{
              marginTop: (theme) => theme.spacing(2),
              height: "100%",
              minHeight: "20rem",
            }}
            pagination
            filterModel={filtPending as GridFilterModel | undefined}
            onFilterModelChange={(newFilterModel) =>
              setFiltPending(newFilterModel as any)
            }
            paginationMode="server"
            disableRowSelectionOnClick
            hideFooter
            columns={columns}
            getRowId={(obj: { id: string }) => obj?.id}
            rows={pending?.data ?? []}
            rowCount={pending?.totalRecordCount}
            loading={loadingPending}
            slots={{
              loadingOverlay: LoadingGridOverlay,
            }}
          />
          <TablePagination
            component="div"
            count={pending?.totalRecordCount}
            page={paginationModelPending.page}
            onPageChange={(e, newpage) =>
              setPaginationModelPending({
                ...paginationModelPending,
                page: newpage,
              })
            }
            rowsPerPage={paginationModelPending.pageSize}
            onRowsPerPageChange={(e) =>
              setPaginationModelPending({
                ...paginationModelPending,
                pageSize: parseInt(e.target.value, 10),
              })
            }
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AltViewGrid;

import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  LinearProgress,
  ListItemText,
  Stack,
  Switch,
  TablePagination,
  TextField,
  styled,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { DataGridPro, GridFilterModel } from "@mui/x-data-grid-pro";
import {
  communityDropdownApi,
  getOptionsCatalog,
  getPlanElevations,
  getPlans,
} from "../../apiCalls";
import {
  CommunityTypes,
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import { numberWithCommas } from "../../utils/formatMoney";
import { isEmpty, isNil, isNull, uniqBy } from "lodash";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import useAccessControl from "../../hooks/useAccessControl";
import { filterOptions } from "../../utils/filterOptions";

const Index = () => {
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");

  useEffect(() => {
    // Function to update the favicon
    const updateFavicon = (faviconURL: string) => {
      const link: HTMLLinkElement =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconURL;
      document.getElementsByTagName("head")[0].appendChild(link);
    };

    // Path to your favicon
    const faviconPath = "/hh-orange-favicon.ico";
    updateFavicon(faviconPath);

    // Update the document title
    document.title = "Change Order - Option Catalog";

    // Cleanup function to reset favicon and title if needed
    return () => {
      const defaultFavicon = "/favicon.ico"; // Replace with your default favicon path if necessary
      updateFavicon(defaultFavicon);
      document.title = "Change Orders"; // Replace with your default title if necessary
    };
  }, []);

  const [searchParams] = useSearchParams();

  const communityParam = searchParams.get("community");
  const planElevationIdParam = searchParams.get("planElevationId");
  const planElevationNumberParam = searchParams.get("planElevationNumber");
  const planIdParam = searchParams.get("planId");
  const planNumberParam = searchParams.get("planNumber");
  const projectIDParam = searchParams.get("projectID");
  const projectNumberParam = searchParams.get("projectNumber");
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );
  const getRowId = (row: {
    salesPriceControlId: string;
    optionCode: string;
    description: string;
  }) => row.salesPriceControlId + row.optionCode + row.description; // This assumes 'optionCode' is unique
  const [filt, setFilt] = React.useState({
    items: [],
    quickFilterValues: [""],
  });
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [options, setOptions] = useState([]);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [pageSize, setPageSize] = React.useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [category, setCategories] = React.useState({
    highlandCategories: [],
    categories: [],
  });
  const [searchOptions, setSearchOptions] = useState<{
    plan: any;
    elevation: any;
    community: CommunityTypes;
    category: {
      category: string;
    };
    highlandCategory: {
      highlandCategory: string;
    };
    IncludeAAAOptions: boolean;
    search: any;
  }>({
    search: "",
    plan: {
      planNumber: "",
      planId: "",
    },
    elevation: {
      planElevationNumber: "",
      planElevationID: "",
    },
    community: {
      projectNumber: "",
      projectId: "",
      name: "",
    },
    highlandCategory: {
      highlandCategory: "",
    },
    category: {
      category: "",
    },
    IncludeAAAOptions: true,
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });

  // Update the pagination model when the TablePagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));

    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: `Change Order Option Catalog` },
    });
    communityDropdownApiAccess && fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);
  useEffect(() => {
    if (searchOptions?.community.projectId) {
      setLoadingOptions(true);

      const params = {
        search: searchOptions?.search,
        ProjectId: searchOptions?.community.projectId,
        PageSize: paginationModel.pageSize,
        highlandCategory:
          searchOptions?.highlandCategory?.highlandCategory ?? "",
        category: searchOptions?.category?.category ?? "",
        PlanId: searchOptions?.plan?.planId ?? "",
        PlanElevationId: searchOptions?.elevation?.planElevationID ?? "",
        IncludeAAAOptions: searchOptions?.IncludeAAAOptions,
        PageNumber: paginationModel?.page + 1, // API might expect 1-indexed pages
      };

      console.log(params, searchOptions);
      GetOptionsCatalogAccess &&
        getOptionsCatalog(
          params,
          (res: any) => {
            setCategories({
              categories: res.data.categories.sort(
                (
                  a: {
                    category: string;
                  },
                  b: {
                    category: string;
                  }
                ) => a.category.localeCompare(b.category)
              ),
              highlandCategories: res.data.highlandCategories.sort(
                (
                  a: {
                    highlandCategory: string;
                  },
                  b: {
                    highlandCategory: string;
                  }
                ) => a.highlandCategory.localeCompare(b.highlandCategory)
              ),
            });

            setOptions(res.data.value);
            setTotalRows(res.data.rowCount); // Set the total number of rows from the response
            setLoadingOptions(false);
            dispatch({
              type: "Snackbar",
              payload: {
                show: true,
                vertical: "bottom",
                horizontal: "left",
                message: `Sales Options Last Updated: ${moment(
                  res.data.optionsLastUpdated
                ).format("LLL")}`,
                severity: "success",
              },
            });
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
            setLoadingOptions(false);
          }
        );
    } else {
      setLoadingOptions(false);
    }
  }, [
    searchOptions,
    paginationModel.page,
    paginationModel.pageSize,
    paginationModel,
    dispatch,
    GetOptionsCatalogAccess,
  ]);

  const confirmColumns = [
    {
      field: "planNumber",
      headerName: "Plan",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "planElevationNumber",
      headerName: "Elevation",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "optionCode",
      headerName: "Option Code",
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 850,
      flex: 1,
    },
    {
      field: "highlandCategory",
      headerName: "Option Category",
      width: 130,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      width: 130,
      flex: 1,
    },
    {
      field: "salesPrice",
      headerName: "Sales Price",
      width: 130,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
      valueFormatter: (params: any) => `$ ${numberWithCommas(params.value)}`,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  useEffect(() => {
    if (projectIDParam) {
      const params = {
        search: searchOptions?.search,
        ProjectId: projectIDParam as string,
        PageSize: paginationModel.pageSize,
        highlandCategory: searchOptions?.highlandCategory?.highlandCategory,
        category: searchOptions?.category?.category,
        PlanId: planIdParam as string,
        PlanElevationId: planElevationIdParam as string,
        IncludeAAAOptions: searchOptions?.IncludeAAAOptions,
        PageNumber: paginationModel?.page + 1, // API might expect 1-indexed pages
      };

      dispatch({
        type: "Navbar",
        payload: {
          title: `Change Order Option Catalog - ${communityParam}`,
        },
      });

      getOptionsCatalog(
        params,
        (res: any) => {
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              vertical: "bottom",
              horizontal: "left",
              message: `Sales Options Last Updated: ${moment(
                res.data.optionsLastUpdated
              ).format("LLL")}`,
              severity: "success",
            },
          });
          setCategories({
            categories: res.data.categories.sort(
              (
                a: {
                  category: string;
                },
                b: {
                  category: string;
                }
              ) => a.category.localeCompare(b.category)
            ),
            highlandCategories: res.data.highlandCategories.sort(
              (
                a: {
                  highlandCategory: string;
                },
                b: {
                  highlandCategory: string;
                }
              ) => a.highlandCategory.localeCompare(b.highlandCategory)
            ),
          });

          setOptions(res.data.value);
          setTotalRows(res.data.rowCount); // Set the total number of rows from the response
          setLoadingOptions(false);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
          setLoadingOptions(false);
        }
      );

      getPlans(
        {
          ProjectID: projectIDParam,
          ProjectNumber: projectNumberParam,
        },
        (res: any) => {
          dispatch({
            type: "AvailablePlans",
            payload: res.data.sort(
              (
                a: {
                  planNumber: string;
                },
                b: {
                  planNumber: string;
                }
              ) => a.planNumber.localeCompare(b.planNumber)
            ),
          });
        },
        (err: any) =>
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          )
      );

      getPlanElevations(
        {
          projectID: projectIDParam as string,
          projectNumber: projectNumberParam as string,
          planId: planIdParam as string,
          planNumber: planNumberParam as string,
        },
        (res: any) => {
          dispatch({
            type: "AvailableElevations",
            payload: res.data.sort(
              (
                a: {
                  planElevationNumber: string;
                },
                b: {
                  planElevationNumber: string;
                }
              ) => a.planElevationNumber.localeCompare(b.planElevationNumber)
            ),
          });
        },
        (err: any) =>
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          )
      );

      setSearchOptions({
        search: "",
        plan: {
          planNumber: planNumberParam,
          planId: planIdParam,
        },
        elevation: {
          planElevationNumber: planElevationNumberParam,
          planElevationID: planElevationIdParam,
        },
        community: {
          projectNumber: projectNumberParam as string,
          projectId: projectIDParam as string,
          name: communityParam as string,
        },
        highlandCategory: {
          highlandCategory: "",
        },
        category: {
          category: "",
        },
        IncludeAAAOptions: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIDParam]);

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          There are currently no Highland Homes catalog options to display.
          Please first select a Community.
        </Box>
      </StyledGridOverlay>
    );
  }

  return (
    <Container component="main" maxWidth={false}>
      <Stack
        mt={2}
        mb={4}
        gap={2}
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "100%",
              md: "80%",
            },
            flexDirection: { xs: "column", md: "row" },
            display: "flex",
          }}
          width={"100%"}
          gap={2}
          display={"flex"}
          alignItems={"center"}
        >
          <TextField
            fullWidth
            size="medium"
            label="Search"
            variant="outlined"
            onChange={(e: any) => (
              setSearchOptions({
                ...searchOptions,
                search: e.target.value,
              }),
              setPaginationModel({
                ...paginationModel,
                page: 0,
              })
            )}
            value={searchOptions.search}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined color="info" />
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            multiple={false}
            fullWidth
            size="medium"
            value={searchOptions.community || null}
            onChange={(e, value, reason) => {
              if (reason === "clear") {
                setSearchOptions({
                  ...searchOptions,
                  plan: {
                    planNumber: "",
                    planId: "",
                  },
                  elevation: {
                    planElevationNumber: "",
                    planElevationID: "",
                  },
                  community: {
                    projectNumber: "",
                    projectId: "",
                    name: "",
                  },
                  highlandCategory: {
                    highlandCategory: "",
                  },
                  category: {
                    category: "",
                  },
                });
                setOptions([]);
              } else if (value) {
                dispatch({
                  type: "Navbar",
                  payload: {
                    title: `Change Order Option Catalog - ${value.name}`,
                  },
                });
                setPaginationModel({
                  ...paginationModel,
                  page: 0,
                });
                setSearchOptions({
                  ...searchOptions,
                  community: value,
                  plan: {
                    planNumber: "",
                    planId: "",
                  },
                  elevation: {
                    planElevationNumber: "",
                    planElevationID: "",
                  },
                });
                GetPlansAccess &&
                  getPlans(
                    {
                      ProjectID: value.projectId,
                      ProjectNumber: value.projectNumber,
                    },
                    (res: any) => {
                      dispatch({
                        type: "AvailablePlans",
                        payload: res.data.sort(
                          (
                            a: {
                              planNumber: string;
                            },
                            b: {
                              planNumber: string;
                            }
                          ) => a.planNumber.localeCompare(b.planNumber)
                        ),
                      });
                    },
                    (err: any) =>
                      dispatch(
                        dispatchError({
                          message: err.message,
                          statusText: err.response.statusText,
                          title: err.response.data.title,
                          status: err.response.status,
                          detail: err.response.data.detail,
                          data: err.response.data,
                        })
                      )
                  );
              }
            }}
            options={state.communities as CommunityTypes[]}
            getOptionLabel={(option) =>
              option.projectNumber
                ? `${option.name} (${option.projectNumber})`
                : option.name
            }
            filterOptions={filterOptions}
            renderOption={(props, item) => (
              <li {...props} key={item.projectId}>
                {" "}
                <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Community"
                fullWidth
                sx={{
                  minWidth: 240,
                }}
              />
            )}
          />
          <Autocomplete
            defaultValue={""}
            disabled={isEmpty(searchOptions.community)}
            fullWidth
            getOptionLabel={(option) => {
              return `${option.planNumber}${
                option.description ? ` - ${option.description}` : ""
              }`;
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.planNumber}>
                <ListItemText>{`${option.planNumber}${
                  option.description ? ` - ${option.description}` : ""
                }`}</ListItemText>
              </li>
            )}
            multiple={false}
            onChange={(e, value, reason) => {
              if (reason === "clear") {
                setSearchOptions({
                  ...searchOptions,
                  plan: {
                    planNumber: "",
                    planId: "",
                  },
                  elevation: {
                    planElevationNumber: "",
                    planElevationID: "",
                  },

                  highlandCategory: {
                    highlandCategory: "",
                  },
                });
              } else if (isNil(value)) {
                setSearchOptions({
                  ...searchOptions,
                  plan: {
                    planNumber: "",
                    planId: "",
                  },
                });
              } else
                setSearchOptions({
                  ...searchOptions,
                  plan: value,
                  elevation: {
                    planElevationNumber: "",
                    planElevationID: "",
                  },
                });

              setPaginationModel({
                ...paginationModel,
                page: 0,
              });
              if (!isNull(value)) {
                if (value.projectID) {
                  console.log("plan", value);
                  const { projectID, projectNumber, planId, planNumber } =
                    value;
                  GetPlanElevationsAccess &&
                    getPlanElevations(
                      { projectID, projectNumber, planId, planNumber },
                      (res: any) => {
                        dispatch({
                          type: "AvailableElevations",
                          payload: res.data.sort(
                            (
                              a: {
                                planElevationNumber: string;
                              },
                              b: {
                                planElevationNumber: string;
                              }
                            ) =>
                              a.planElevationNumber.localeCompare(
                                b.planElevationNumber
                              )
                          ),
                        });
                      },
                      (err: any) =>
                        dispatch(
                          dispatchError({
                            message: err.message,
                            statusText: err.response.statusText,
                            title: err.response.data.title,
                            status: err.response.status,
                            detail: err.response.data.detail,
                            data: err.response.data,
                          })
                        )
                    );
                }
              }
            }}
            options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
              return obj.description;
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Plan"
                fullWidth
              />
            )}
            size="medium"
            value={searchOptions.plan || null}
          />
          <Autocomplete
            multiple={false}
            fullWidth
            size="medium"
            defaultValue={""}
            disabled={isEmpty(searchOptions.plan)}
            value={searchOptions.elevation || null}
            onChange={(e, value, reason) => {
              console.log(reason);
              if (reason === "clear") {
                setSearchOptions({
                  ...searchOptions,
                  elevation: {
                    planElevationNumber: "",
                    planElevationID: "",
                  },

                  highlandCategory: {
                    highlandCategory: "",
                  },
                });
              } else if (isNil(value)) {
                setSearchOptions({
                  ...searchOptions,
                  elevation: {
                    planElevationNumber: "",
                    planElevationID: "",
                  },
                });
              } else
                setPaginationModel({
                  ...paginationModel,
                  page: 0,
                });
              setSearchOptions({
                ...searchOptions,
                elevation: value,
              });
            }}
            options={state.availableElevations as ElevationTypes[]}
            getOptionLabel={(option) => `${option.planElevationNumber}`}
            renderOption={(props, option) => (
              <li {...props} key={option.planElevationNumber}>
                <ListItemText>{option.planElevationNumber}</ListItemText>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Elevation"
                fullWidth
              />
            )}
          />
          <Autocomplete
            multiple={false}
            fullWidth
            size="medium"
            //@ts-ignore
            value={searchOptions.highlandCategory || null}
            onChange={(e, value, reason) => {
              if (reason === "clear") {
                setSearchOptions({
                  ...searchOptions,
                  highlandCategory: {
                    highlandCategory: "",
                  },
                });
              } else
                setPaginationModel({
                  ...paginationModel,
                  page: 0,
                });
              setSearchOptions({
                ...searchOptions,
                highlandCategory: value as any,
              });
            }}
            options={category.highlandCategories}
            getOptionLabel={(option: { highlandCategory: string }) =>
              option.highlandCategory
            }
            renderOption={(props, option) => (
              <li {...props} key={option?.highlandCategory}>
                <ListItemText>{option?.highlandCategory}</ListItemText>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Option Category"
                fullWidth
              />
            )}
          />
          <Autocomplete
            multiple={false}
            fullWidth
            size="medium"
            //@ts-ignore
            value={searchOptions.category || null}
            onChange={(e, value, reason) => {
              if (reason === "clear") {
                setSearchOptions({
                  ...searchOptions,
                  category: {
                    category: "",
                  },
                });
              } else
                setPaginationModel({
                  ...paginationModel,
                  page: 0,
                });
              setSearchOptions({
                ...searchOptions,
                category: value as any,
              });
            }}
            options={category.categories}
            getOptionLabel={(option: { category: string }) => option.category}
            renderOption={(props, option) => (
              <li {...props} key={option?.category}>
                <ListItemText>{option?.category}</ListItemText>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Category"
                fullWidth
              />
            )}
          />
          <FormGroup
            sx={{
              width: "100%",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={searchOptions.IncludeAAAOptions}
                  onChange={(e, checked) => {
                    setPaginationModel({
                      ...paginationModel,
                      page: 0,
                    });
                    setSearchOptions({
                      ...searchOptions,
                      IncludeAAAOptions: checked,
                    });
                  }}
                  inputProps={{ "aria-label": "Show Options" }}
                />
              }
              label="Show Options"
            />
          </FormGroup>
        </Box>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            setPaginationModel({
              ...paginationModel,
              page: 0,
            });
            setSearchOptions({
              search: "",
              plan: {
                planNumber: "",
                planId: "",
              },
              elevation: {
                planElevationNumber: "",
                planElevationID: "",
              },
              community: {
                projectNumber: "",
                projectId: "",
                name: "",
              },
              highlandCategory: {
                highlandCategory: "",
              },
              category: {
                category: "",
              },
              IncludeAAAOptions: true,
            });
            setOptions([]);
          }}
        >
          Reset
        </Button>
      </Stack>
      <DataGridPro
        paginationMode="server"
        initialState={{
          sorting: {
            sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
          },
        }}
        density="compact"
        rowCount={totalRows}
        hideFooter
        loading={loadingOptions}
        //@ts-ignore
        columns={confirmColumns}
        rows={options}
        getRowId={getRowId}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        filterModel={filt as GridFilterModel | undefined}
        onFilterModelChange={(newFilterModel) => {
          console.log(newFilterModel);
          setFilt(newFilterModel as any);
        }}
        sx={{
          minHeight: "40rem",
          "& .css-1iledgx-MuiFormControl-root": {
            marginBottom: "0px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible!important",
          },
        }}
        pagination
      />
      <TablePagination
        component="div"
        count={totalRows} // Total row count comes from the server
        page={paginationModel.page}
        nextIconButtonProps={{
          disabled: pageSize > options.length ? true : false,
        }}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
      />
    </Container>
  );
};

export default Index;

export const getEnvironmentFromURL = (): string => {
  const url = new URL(window.location.href);
  const hostname = url.hostname;
  const subdomain = hostname.split(".")[0]; // Get the subdomain part of the hostname

  switch (subdomain) {
    case "localhost":
      return "Local Environment";
    case "co-dev":
      return "Development Environment";
    case "co-tst":
      return "Test Environment";
    case "co-uat":
      return "User Test Environment";
    case "co":
      return "Production Environment";
    default:
      return ""; // Fallback in case the URL doesn't match
  }
};

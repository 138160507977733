import {
  //useState,
  useContext,
  useEffect,
} from "react";
import { dispatchError } from "../../common/fx";
import ReusableDialog from "../../components/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { UIContext, UIState } from "../../providers/UIProvider";
//import { useNavigate } from "react-router-dom";
import {
  // startNewIntention,
  // newIntention,
  communityDropdownApi,
  // getAllRoles,
  AddUserAdmin,
  getAllUsers,
  SetUserAccess,
} from "../../apiCalls";
import Step1 from "./Step1";
import Step2 from "./Step2";
import useAccessControl from "../../hooks/useAccessControl";

const AddUser = ({
  form,
  setForm,
  open,
  setOpen,
  step,
  setStep,
  userList,
  setUserList,
  getUserPost,
}: any) => {
  //const navigate = useNavigate();
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );

  const [state, dispatch] = useContext<UIState | any>(UIContext);

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  useEffect(() => {
    communityDropdownApiAccess && fetchCommunities();
    // fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  const AddUserLogic = () => {
    AddUserAdmin(
      {
        UserObjectId: form?.user?.userObjectId,
        UserPrincipalName: form?.user?.userPrincipalName,
        UserName: form?.user?.fullName,
      },
      (res: any) => {
        setStep(1);
        getUserPost();
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const ModifyUser = () => {
    console.log(form);
    SetUserAccess(
      {
        userObjectId: form?.user?.userObjectId,
        roles: form?.role?.map((obj: { name: string }) => obj?.name),
        communities: form.communities,
      },
      (res: any) => {
        setOpen(false);
        getUserPost();
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${form?.user?.fullName}'s access permission has been successfully updated`,
            severity: "success",
          },
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };
  return (
    <ReusableDialog
      setIsOpen={(value) => setOpen(value)}
      isOpen={open}
      onChange={() => {
        setStep(0);
        setForm({
          communities: [],
          role: [],
          user: { fullName: "", userPrincipalName: "", userObjectId: "" },
        });
      }}
      maxWidth={"lg"}
      content={
        <>
          <DialogContent>
            {step === 0 && <Step1 form={form} setForm={setForm} />}
            {step === 1 && (
              <Step2
                userList={userList}
                setUserList={setUserList}
                form={form}
                setForm={setForm}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                dispatch({
                  type: "ResetCreateChangeOrderForm",
                });
              }}
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>

            {form?.user?.userPrincipalName !==
              state.isAdmin.userPrincipalName && (
              <Button
                onClick={() => {
                  if (step === 0) {
                    AddUserLogic();
                  } else ModifyUser();
                }}
                variant="contained"
                color="primary"
              >
                {step === 0 && "Add User"}
                {step === 1 && "Modify User"}
              </Button>
            )}
          </DialogActions>
        </>
      }
      buttonText="Add User"
      title={step === 0 ? "Add User" : "Modify User"}
    />
  );
};

export default AddUser;

import React, { useContext, useEffect, useState } from "react";
import { Link as RLink } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Container,
  IconButton,
  LinearProgress,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";
import {
  DataGridPro,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import {
  communityDropdownApi,
  deleteTemplate,
  listAllTemplates,
  renameTemplate,
} from "../../apiCalls";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import useAccessControl from "../../hooks/useAccessControl";
import { filterOptions } from "../../utils/filterOptions";
import ValidatedInput from "../../components/ValidatedInput";
import DeleteConfirmation from "../../modals/DeleteConfirmation";
import moment from "moment";

const Index = () => {
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );

  const [filt, setFilt] = React.useState({
    items: [],
    quickFilterValues: [""],
  });
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [totalRows, setTotalRows] = useState(0);
  const [communities, setCommunities] = useState<any[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<{
    status: boolean;
    templateName: "";
    id: "";
  }>({ status: false, templateName: "", id: "" });

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  const handleUpdateTemplate = (objParam: {
    templateId: string;
    newName: string;
  }) => {
    const updateTemplateArr = templates.map(
      (obj: { id: string; templateName: string }) =>
        obj.id === objParam.templateId
          ? { ...obj, templateName: objParam.newName }
          : obj
    );

    renameTemplate(
      objParam,
      (res: any) => {
        setTemplates(updateTemplateArr as any);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `Template name has been updated to ${objParam.newName}`,
            severity: "success",
          },
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const handleDeleteRow = (id: any, changeOrderNumber: any) => {
    deleteTemplate(
      id,
      (res: any) => {
        const deleteTemplate = templates.filter(
          (obj: { id: number }) => obj.id !== id
        );

        setTemplates(deleteTemplate);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `Template has been deleted.`,
            severity: "success",
          },
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: `Template Management` },
    });
    communityDropdownApiAccess && fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  useEffect(() => {
    setLoadingOptions(true);

    listAllTemplates(
      (res: any) => {
        setTemplates(res.data ?? []);
        setLoadingOptions(false);
      },
      (err: any) => {
        dispatch(dispatchError(err));
        setLoadingOptions(false);
      }
    );
  }, []);

  useEffect(() => {
    if (communities.length === 0) {
      // If no community is selected, show the original full list of templates
      setFilteredTemplates(templates);
      setTotalRows(templates.length);
    } else {
      // Otherwise, filter based on the selected communities
      const filtered = templates.filter((template: { projectId: number }) =>
        communities.some(
          (community) => community.projectId === template?.projectId
        )
      );
      setFilteredTemplates(filtered);
      setTotalRows(filtered.length);
    }
  }, [communities, templates]);

  const confirmColumns = [
    {
      field: "templateName",
      headerName: "Template Name",
      flex: 1,
      maxWidth: 450,
      renderCell: (params: GridRenderCellParams) => (
        <ValidatedInput
          disabled={!params?.row?.templateOwner}
          className="templateName"
          editTitle="Template Name"
          inputVariant="standard"
          mode="editor"
          onChange={(newValue) =>
            handleUpdateTemplate({
              templateId: params.row.id,
              newName: newValue,
            })
          }
          type="text"
          value={params.row.templateName}
          verify={false}
          saveTitle="Save this name"
        />
      ),
    },
    {
      field: "community",
      headerName: "Community",
      flex: 1,
      width: 500,
      renderCell: (params: GridRenderCellParams) =>
        state.communities.find(
          (obj: { projectId: number }) => obj.projectId === params.row.projectId
        )?.name,
    },
    {
      field: "planNumber",
      headerName: "Plan Number",
      flex: 1,
      width: 400,
    },

    {
      field: "optionCount",
      headerName: "# Of Options",
      width: 100,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      width: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          placement="right-start"
          title={`Created On ${moment(params.row.createdAt).format(
            "MM-DD-YYYY"
          )} `}
        >
          <span>{moment(params.row.createdAt).format("MM-DD-YYYY")}</span>
        </Tooltip>
      ),
    },
    {
      field: "createdByName",
      headerName: "Created By",
      width: 100,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "",
      width: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <RLink
            to={{
              pathname: "/template-summary",
              search: `?templateId=${params.row.id}`,
            }}
          >
            <Tooltip title={`View Template ${params.row.templateName}`}>
              <IconButton>
                <Visibility />
              </IconButton>
            </Tooltip>
          </RLink>
          <Tooltip title="Delete">
            <IconButton
              disabled={!params?.row?.templateOwner}
              onClick={() =>
                setConfirmDelete({
                  status: true,
                  templateName: params?.row?.templateName,
                  id: params?.row?.id,
                })
              }
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          There are currently no Highland Homes catalog options to display.
          Please first select a Community.
        </Box>
      </StyledGridOverlay>
    );
  }

  return (
    <Container component="main" maxWidth={false}>
      <Stack
        mt={2}
        mb={4}
        gap={2}
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "100%",
              md: "40%",
            },
            flexDirection: { xs: "column", md: "row" },
            display: "flex",
          }}
          width={"100%"}
          gap={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            size="small"
            onChange={async (events, value) => {
              setCommunities(value);
            }}
            options={state.communities as CommunityTypes[]}
            getOptionLabel={(option) =>
              `${option.name} (${option.projectNumber})`
            }
            value={communities}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Community"
                fullWidth
              />
            )}
            filterOptions={filterOptions}
            renderOption={(props, item) => (
              <li {...props} key={item.projectId}>
                <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
              </li>
            )}
          />
        </Box>
      </Stack>
      <DeleteConfirmation
        setIsOpen={() =>
          setConfirmDelete({ status: false, templateName: "", id: "" })
        }
        deleteMessage={`Delete the template: ${confirmDelete.templateName} ?`}
        deleteTitle={"Delete Template"}
        onClose={() =>
          setConfirmDelete({ status: false, templateName: "", id: "" })
        }
        isOpen={confirmDelete.status}
        confirmDelete={() => {
          setConfirmDelete({ status: false, templateName: "", id: "" });
          handleDeleteRow(confirmDelete.id, confirmDelete.templateName);
        }}
      />
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
          },
        }}
        density="compact"
        rowCount={totalRows}
        loading={loadingOptions}
        //@ts-ignore
        columns={confirmColumns}
        rows={filteredTemplates}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        filterModel={filt as GridFilterModel | undefined}
        onFilterModelChange={(newFilterModel) => {
          console.log(newFilterModel);
          setFilt(newFilterModel as any);
        }}
        sx={{
          minHeight: "40rem",
          "& .css-1iledgx-MuiFormControl-root": {
            marginBottom: "0px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible!important",
          },
        }}
        pagination
      />
      {/* <TablePagination
        component="div"
        count={totalRows} // Total row count comes from the server
        page={page}
        nextIconButtonProps={{
          disabled: pageSize > templates.length ? true : false,
        }}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
      /> */}
    </Container>
  );
};

export default Index;

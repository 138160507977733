import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
} from "@mui/material";

interface ConfirmDeleteTemplateProps {
  confirmDelete: () => void;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  onClose: any;
  deleteTitle: string;
  deleteMessage: string | any;
  actionText?: string;
}

const DeleteConfirmation = ({
  confirmDelete,
  setIsOpen,
  isOpen,
  onClose,
  deleteTitle,
  deleteMessage,
  actionText,
}: ConfirmDeleteTemplateProps) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      data-testid="dialog-wrapper"
      onClose={() => setIsOpen(false)}
      fullWidth
    >
      <DialogTitle
        data-testid={"dialog-delete-template"}
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        })}
      >
        {deleteTitle}
      </DialogTitle>
      <DialogContent>
        <>
          <Box marginY={(theme) => theme.spacing(2)}>{deleteMessage}</Box>
        </>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            confirmDelete();
            handleClose();
          }}
          variant="contained"
          color="primary"
        >
          {actionText ?? "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;

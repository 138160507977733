import React from "react";
import { jobsApiFiltered } from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import { UIContext, UIState } from "../../providers/UIProvider";
import { DataGridPro, GridFilterModel } from "@mui/x-data-grid-pro";
import { TablePagination } from "@mui/material";
import LoadingGridOverlay from "../../components/Loading/LoadingGridOverlay";
import axios, { CancelTokenSource } from "axios";

const ChangeOrderViewGrid = ({
  columns,
  searchText,
  communities,
  setPage,
  page,
}: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);

  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [filt, setFilt] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  const [totalRows, setTotalRows] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(50);
  const [cancelTokenSource, setCancelTokenSource] =
    React.useState<CancelTokenSource | null>(null);

  // Update the pagination model when the TablePagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));

    setPage(0); // Reset to the first page
  };

  React.useEffect(() => {
    const fetchAddresses = (
      paginationModel: {
        page: number;
        pageSize: number;
      },
      setData: any,
      setLoading: any
    ) => {
      setLoading(true);
      // Cancel the previous request if exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled due to new request.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      jobsApiFiltered(
        {
          searchText: searchText,
          jobType: null,
          communities: communities.map((obj: any) => String(obj.projectId)),
          pageNumber: page + 1,
          pageSize: pageSize,
        },

        (res: any) => {
          return (
            setTotalRows(res.data.totalRecordCount),
            setData(res.data.summaries),
            setLoading(false)
          );
        },
        (err: any) => {
          if (err.code === "ERR_CANCELED") {
            console.log(err);
          } else {
            dispatch(dispatchError(err.response.data));
          }
        },
        newCancelTokenSource
      );
    };

    fetchAddresses({ page: page, pageSize: pageSize }, setData, setLoading);

    dispatch({
      type: "Navbar",
      payload: { title: `Change Order` },
    });

    return () => {
      // Cleanup function to cancel the request when component unmounts or dependencies change
      if (cancelTokenSource) {
        cancelTokenSource.cancel(
          "Operation canceled due to component unmounting or dependencies change."
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filt, searchText, page, pageSize, dispatch, communities]);

  return (
    <>
      <DataGridPro
        scrollbarSize={10}
        sx={{
          marginTop: (theme) => theme.spacing(2),
          height: "100%",
          minHeight: "20rem",
          "& .MuiDataGrid-virtualScroller": {
            transform: "rotateX(180deg)",
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            transform: "rotateX(180deg)",
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "lastUpdated", sort: "desc" }],
          },
        }}
        paginationMode="server"
        data-testid={"main-view-grid"}
        pagination
        getRowId={(obj: { id: string }) => obj.id}
        disableRowSelectionOnClick
        filterModel={filt as GridFilterModel | undefined}
        onFilterModelChange={(newFilterModel) => setFilt(newFilterModel as any)}
        columns={columns}
        rows={data}
        rowCount={totalRows}
        slots={{
          loadingOverlay: LoadingGridOverlay,
        }}
        hideFooter
        loading={loading}
      />
      <TablePagination
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
      />
    </>
  );
};
export default ChangeOrderViewGrid;

import { CancelTokenSource } from "axios";
import axiosInstance from "./utils/axiosInstance";
import { RoleType } from "./providers/UIProvider";

export interface buildSummaryParams {
  ProjectID?: string;
  projectNumber?: string;
  community?: string;
  jobKey?: string;
  search?: string;
  pageSize?: number;
  pageNumber?: number;

  [key: string]: any;
}

export interface adressParams {
  jobkey?: string;
  projectNumber?: string;
  jobNumber?: string;
  community?: string;
  projectID?: string;
  jobID?: string;
  [key: string]: any;
}

export interface planParams {
  projectID?: string;
  projectNumber?: string;
  community?: string;
  planId?: string;
  planNumber?: string;
  [key: string]: any;
}

export interface findCustomSF {
  BuyerName?: string;
  PageSize?: number;
  PageNumber?: number;
  CommunityName?: string;
  [key: string]: any;
}

export interface findBuyerBySfAccountIdParams {
  salesforceAccountId: string;
  communityName: string;
}

export interface findCustomAdmin {
  search?: string;
  Top?: number;
  [key: string]: any;
}

export interface retriveOptions {
  ProjectId?: string;
  PlanId?: number;
  PlanElevationId?: number;
  OptionId?: string;
  ProjectNumber?: string;
  PlanNumber?: number;
  PlanElevationNumber?: string;
  OptionAbbreviation?: number;
  Search?: string;
  [key: string]: any;
}

export interface getOptionsCatalogTypes {
  ProjectId?: string;
  PageSize?: number;
  PageNumber?: number;

  [key: string]: any;
}

export interface retriveOption {
  optionId?: string;
  [key: string]: any;
}

export interface changeOrderOptions {
  salesPriceControlID: number;
  optionCode: string;
  description: string;
  optionCategoryID: number;
  optionId: number;
  category: string;
  quantity: number;
  unitPrice: number;
  isNew?: boolean;
  active: true;
  notes: string;
  [key: string]: any;
}

export interface ConstructionManagerOptions {
  ChangeOrderId: string;
  CMName: string;
  SuperintendentID: number;
}

export interface newIntention {
  projectID: number;
  projectNumber: string;
  buyerFirstName?: string;
  isSpec: boolean;
  addressToBeDetermined: boolean;
  isPreliminary: boolean;
  addressFreeform?: string;
  planOverride?: string;
  planElevationOverride?: string;
  buyerLastName?: string;
  salesforceAccountID?: string;
  jobID?: number;
  jobNumber?: string;
  address?: string;
  planID: number;
  planElevationID: number;
  [key: string]: any;
}

export interface convertIntentionType {
  intention: string;
  buyerChanges: {
    salesforceAccountId: string;
    salesforceConnectionNumber: string;
    buyerFirstName: string;
    buyerLastName: string;
    buyerLegalName: string;
  };
}

export interface convertToJobType {
  intention: string;
  jobId: number;
  jobNumber: string;
}

export interface updateKeywordType {
  intentionId: string;
  keyword: string;
}

export interface priceAdjustmentPayload {
  buyerFirstName: string;
  buyerLastName: string;
  buyerLegalName: string;
  salesForceAccountId: string;
  salesForceConnectionNumber: string;
  adjustmentPrice: number;
  adjustmentReason: string;
  intentionsList: string[];
}

export interface GetTemplateOptions {
  projectId: number;
  planId: number;
}

export interface SaveTemplateParams {
  intentionId: string;
  templateName: string;
  isPublic: boolean;
}

export interface GetMeasuredOptionsParams {
  projectId: string;
  planId: string;
}

const toQueryString = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      )
      .join("&")
  );
};

export const communityDropdownApi = (callback: any, errorcallback: any) => {
  axiosInstance
    .get(`/api/Communities/active`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const communityDropdownAllApi = (callback: any, errorcallback: any) => {
  axiosInstance
    .get(
      `/api/Communities/active/${toQueryString({
        completeActiveList: true,
      })}`
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

// export const jobsApi = (
//   objParam: buildSummaryParams,
//   callback: any,
//   errorcallback: any
// ) => {
//   axiosInstance
//     .get(`/api/ListSummaries/${toQueryString(objParam)}`)
//     .then((res) => {
//       callback(res);
//     })
//     .catch((err) => {
//       errorcallback(err);
//     });
// };

export const jobsApiFiltered = (
  objParam: {
    searchText: string;
    communities?: string[];
    jobType: string | null;
    pageSize: number;
    pageNumber: number;
  },
  callback: any,
  errorcallback: any,
  cancelToken?: CancelTokenSource
) => {
  axiosInstance
    .post(
      `/api/ListSummariesByFilter/`,
      { ...objParam },
      { cancelToken: cancelToken?.token }
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getChangeOrderLinks = (callback: any, errorcallback: any) => {
  axiosInstance
    .get(`/api/ChangeOrderLinks/GetChangeOrderLinks`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getIntentionOrderApi = (
  id: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/IntentionSummaryDetail/${id}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

// export const changeOrderItemsIDApi = (
//   id: string,
//   callback: any,
//   errorcallback: any
// ) => {
//   axiosInstance
//     .get(`/api/ChangeOrderItems/${id}`)
//     .then((res) => {
//       callback(res);
//     })
//     .catch((err) => {
//       errorcallback(err);
//     });
// };

export const allChangeOrderOptions = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/Job/AllOptions`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getAddress = (
  objParam: adressParams,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Jobs/Address${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getPlans = (
  objParam: planParams | null,
  callback: any,
  errorcallback: any
) => {
  if (objParam) {
    axiosInstance
      .get(`/api/Plan/GetPlans${toQueryString(objParam)}`)
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        errorcallback(err);
      });
  } else {
    axiosInstance
      .get(`/api/Plan/GetPlans`)
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        errorcallback(err);
      });
  }
};

export const getPlanElevations = (
  objParam: planParams,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Plan/GetPlanElevations${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const findCustomerByNameSFQuote = (
  objParam: findCustomSF,
  callback: any,
  errorcallback: any,
  cancelToken?: CancelTokenSource
) => {
  axiosInstance
    .get(`/api/Quote/ListQuoteBuyers${toQueryString(objParam)}`, {
      cancelToken: cancelToken?.token,
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const findCustomerByNameSF = (
  objParam: findCustomSF,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Customer/FindCustomerByNameFromSF${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const findBuyerBySfAccountId = (
  objParam: findBuyerBySfAccountIdParams,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(
      `api/Customer/FindCustomerBySfAccountIdCommunityName${toQueryString(
        objParam
      )}`
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const retrieveAllOptions = (
  objParam: retriveOptions,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Options${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

// export const retrieveOptionByID = (
//   objParam: retriveOption,
//   callback: any,
//   errorcallback: any
// ) => {
//   axiosInstance
//     .get(`/api/option${toQueryString(objParam)}`)
//     .then((res) => {
//       callback(res);
//     })
//     .catch((err) => {
//       errorcallback(err);
//     });
// };

export const retrieveOptionCategories = (
  objParam: retriveOptions,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/OptionsCategory${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getOptionsCatalog = (
  objParam: getOptionsCatalogTypes,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/OptionsCatalog${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getConstructionManagers = (
  jobId: number,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/GetConstructionManager${toQueryString({ jobId })}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const upsertConstructionManager = (
  objParam: ConstructionManagerOptions,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`api/UpsertJobCM${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const startNewIntention = (
  objParam: newIntention,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/StartNewIntention`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

// export const startNewSubordinateIntention = (
//   objParam: { parentIntentionId: string },
//   callback: any,
//   errorcallback: any
// ) => {
//   axiosInstance
//     .post(`/api/StartNewSubordinateIntention`, { ...objParam })
//     .then((res) => {
//       callback(res);
//     })
//     .catch((err) => {
//       errorcallback(err);
//     });
// };

export const modifyOptions = (
  intentionId: string,
  objParam: {
    options: changeOrderOptions[];
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/ModifyOptions/${intentionId}`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getQuote = (
  quoteId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Quote/${quoteId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export const getIntention = (
  intentionId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Intention/${intentionId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getIntentionUpdates = (
  intentionId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`api/IntentionUpdateNotifications/${intentionId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const downloadChangeOrderPDF = (
  objParam: {
    changeOrderId: string;
    isSubmitPreview?: boolean;
    buyerCount?: number;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/DownloadPDF${toQueryString(objParam)}`)
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const storeChangeOrderPDF = (
  objParam: {
    changeOrderId: string;
    isSubmitPreview?: boolean;
    buyerCount?: number;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/StorePDF${toQueryString(objParam)}`)
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const convertIntention = (
  objParam: convertIntentionType,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/UpdateIntention`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const convertToJob = (
  objParam: convertToJobType,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/ConvertToJob`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const updateKeyword = (
  objParam: updateKeywordType,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(
      `api/UpdateKeyword/${objParam.intentionId}?keyword=${objParam.keyword}`
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getLastSubmittedCO = (
  objParam: {
    intentionId: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/GetLastSubmittedCoNumber?intentionId=${objParam.intentionId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const unSubmitIntention = (
  objParam: {
    intentionId: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/UnsubmitIntention?intentionId=${objParam.intentionId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const submitIntention = (
  intentionId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/SubmitIntention/${intentionId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const modelFromExistingIntention = (
  intentionId: string,
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/ModelFromExistingIntention/${intentionId}`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

//Admin Api Calls

export const userAdminSearchUser = (
  objParam: findCustomAdmin,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/SearchUser${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getAllUsersPost = (
  objParam: {
    pageSize: number;
    pageNumber: number;
    search: string;
    activeOnly?: boolean | null;
    roles: RoleType[];
    communities: any[];
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/Users`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getAllUsers = (
  objParam: {
    PageSize: number;
    PageNumber: number;
    search: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/Users${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const getAllRoles = (callback: any, errorcallback: any) => {
  axiosInstance
    .get(`/api/GetRoles`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const listRoleAccess = (
  roleName: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/ListRoleAccess?roleName=${roleName}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const toggleRoleAccess = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(
      `/api/ToggleRoleAccess?roleName=${objParam.roleName}&controller=${objParam.controller}&action=${objParam.action}`
    )
    .then((res: any) => {
      callback(res);
    })
    .catch((err: any) => {
      errorcallback(err);
    });
};

export const getTemplates = (
  objParam: GetTemplateOptions,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/OptionTemplate/ListTemplates`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const loadTemplate = (
  templateId: string,
  planElevationId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(
      `/api/OptionTemplate/LoadTemplate?templateId=${templateId}&planElevationId=${planElevationId}`
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const deleteTemplate = (
  templateId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .delete(`/api/OptionTemplate/DeleteTemplate?templateId=${templateId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const saveTemplate = (
  templateData: SaveTemplateParams,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/OptionTemplate/SaveTemplate`, templateData)
    .then((res) => {
      callback(res);
    })
    .catch((res) => {
      errorcallback(res);
    });
};

export const getMeasuredOptions = (
  params: GetMeasuredOptionsParams,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(
      `/api/MeasuredOptionsWizard?projectId=${params.projectId}&planId=${params.planId}`
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const AddUserAdmin = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/AddUser`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const SetUserAccess = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/SetUserAccess`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const UpdateUserStatus = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/UpdateUserStatus`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const retrieveUserInfo = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/UserDetail${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const deleteChangeOrder = (
  intentionId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .delete(`/api/DeleteIntention/${intentionId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const intentionNotes = (
  objParam: object,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/IntentionNotes/${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};
export const deleteNote = (
  objParam: object,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .delete(`/api/DeleteNote/${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const createNote = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/CreateNote`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

// export const pinNoteToTop = (
//   objParam: any,
//   callback: any,
//   errorcallback: any
// ) => {
//   axiosInstance
//     .put(`/api/CreateNote${toQueryString(objParam)}`)
//     .then((res) => {
//       callback(res);
//     })
//     .catch((err) => {
//       errorcallback(err);
//     });
// };

// export const adminOnlyNote = (
//   objParam: any,
//   callback: any,
//   errorcallback: any
// ) => {
//   axiosInstance
//     .put(`/api/CreateNote${toQueryString(objParam)}`)
//     .then((res) => {
//       callback(res);
//     })
//     .catch((err) => {
//       errorcallback(err);
//     });
// };

export const priceAdjustment = (
  objParam: priceAdjustmentPayload,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/PriceAdjustment`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const JobOptionsSummary = (
  objParam: {
    optionIds: string[];
    showPrices: boolean;
    useTemplateForPrinting: boolean;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/job/JobOptionsSummary`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const JobOptionsSummaryExcel = (
  objParam: {
    optionIds: string[];
    showPrices: boolean;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/options/ExportSubmitted`, { ...objParam })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const JobHasSubmittedBasePlan = (
  objParam: {
    jobId: number;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`api/HasSubmittedBasePlan${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const JobHasSubmittedIntention = (
  objParam: {
    jobId: number;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`api/HasSubmittedIntention${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const ChangeElevation = (
  objParam: {
    elevationChangePriceAdjust: number;
    useSpecBuyer: boolean;
    buyerCount: number;
    planElevationOptionId: string;
    newPlanElevationSalesPriceControlId: string;
    expectedPriceDifference: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/ChangeElevation`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const UpdateElevation = (
  objParam: any,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/UpdateElevation`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const listAllTemplates = (callback: any, errorcallback: any) => {
  axiosInstance
    .post(`/api/OptionTemplate/ListAllUserTemplates`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const renameTemplate = (
  objParam: {
    templateId?: string;
    newName?: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .patch(`/api/OptionTemplate/RenameTemplate${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const LoadTemplateDetails = (
  objParam: {
    templateId?: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/OptionTemplate/LoadTemplateDetails${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const GetParentIntentionId = (
  objParam: {
    jobId: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/GetParentIntentionId${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const ChangePlan = (
  objParam: {
    planElevationOptionId: string;
    intentionId: string;
    newPlanElevationSalesPriceControlId: string;
    salesPriceControlId: string;
    expectedPriceDifference: string;
    useSpecBuyer: boolean;
    buyerCount: number;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/ChangePlan`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};
export const UpdatePlan = (
  objParam: {
    intentionId: string;
    newPlanElevationId: string;
    salesPriceControlId: string;
    jobId: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/UpdatePlan`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const GetSubmittedBasePlan = (
  objParam: {
    jobId?: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/GetSubmittedBasePlan${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const GetSystemMessages = (callback: any, errorcallback: any) => {
  axiosInstance
    .get(`/api/SystemMessages/Get`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const GetAllSystemMessages = (
  objParam: {
    messageType?: "KnownIssue" | "NewFeature" | "SystemMaintenance" | "";
    includeExpired?: boolean;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/SystemMessages/GetAll${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const AddSystemMessage = (
  objParam: {
    type?: "KnownIssue" | "NewFeature" | "SystemMaintenance" | "";
    title: string;
    message: string;
    expires: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/SystemMessages/Add`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const DeleteSystemMessage = (
  messageId: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .delete(`/api/SystemMessages/Delete?messageId=${messageId}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const EditSystemMessage = (
  objParam: {
    id?: string;
    title?: string;
    type?: string;
    message?: string;
    expires?: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .patch(`/api/SystemMessages/Edit`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const BustaJob = (
  objParam: {
    jobId?: string;
    buyerCount?: number;
    useSpecBuyer?: boolean;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/Job/BustaJob${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const GetReports = (callback: any, errorcallback: any) => {
  axiosInstance
    .get(`api/Report/GetReports`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const IsEmptyJobByIntentionId = (
  objParam: {
    intentionId?: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .get(`/api/IsEmptyJobByIntentionId${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};
export const GetReport = (
  reportName: string,
  callback: any,
  errorcallback: any
) => {
  axiosInstance(`api/Report/GetReportDetail?reportName=${reportName}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};
export const GetAddressId = (
  objParam: {
    jobId: string;
    planId?: string;
    planElevationId?: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance(`api/GetAddressId${toQueryString(objParam)}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const listQuoteSummaries = (
  objParam: {
    searchText?: string;
    communities?: string[];
    jobType?: string | null;
    pageSize?: number;
    pageNumber?: number;
  },
  callback: any,
  errorcallback: any,
  cancelToken?: CancelTokenSource
) => {
  axiosInstance
    .get(`/api/Quote/ListQuoteSummaries`)
    .then((res) => {
      callback({
        totalRecordCount: 2,
        summaries: res,
      });
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const AddNewQuoteBuyer = (
  objParam: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    SalesforceAccountId: string;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/Quote/AddNewQuoteBuyer`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};
export const AddNewQuote = (
  objParam: {
    quoteBuyerId?: string;
    projectId: string;
    planElevationId: string;
    jobId: number;
  },
  callback: any,
  errorcallback: any
) => {
  axiosInstance
    .post(`/api/Quote/AddNewQuote`, objParam)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorcallback(err);
    });
};

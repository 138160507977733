import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import { numberWithCommas } from "../../utils/formatMoney";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../../styles/MainTheme";
import { Button, CssBaseline, Container, Dialog, DialogActions, DialogContent, Skeleton, Stack } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useSearchParams, useNavigate } from "react-router-dom";
import ValidatedInput from "../../components/ValidatedInput";
import OptionCategories from "../../components/OptionCategories";
import {
  LineItems,
  OptionsCategory,
  OptionTypes,
  HighlandCategoryOption,
  UIContext,
  UIState,
  ConstructionManagerTypes,
} from "../../providers/UIProvider";
import {
  convertIntention,
  convertIntentionType,
  convertToJob,
  downloadChangeOrderPDF,
  findBuyerBySfAccountId,
  getLastSubmittedCO,
  getTemplates,
  getIntention,
  JobHasSubmittedBasePlan,
  JobHasSubmittedIntention,
  retrieveAllOptions,
  retrieveOptionCategories,
  upsertConstructionManager,
  getIntentionUpdates,
} from "../../apiCalls";
import EditFooter from "./EditFooter";
import PrepareChangeOrder from "../../modals/PrepareChangeOrder/PrepareChangeOrder";
import useAccessControl from "../../hooks/useAccessControl";
import "./Edit.scss";
import PDFPreview from "../../modals/PDFPreview";


export default function Edit() {
  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");
  const [isChangeOrderLoaded, setIsChangeOrderLoaded] =
    useState<boolean>(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [constructionManager, setConstructionManager] =
    useState<ConstructionManagerTypes | null>(null);
  const [previousCO, setPreviousCO] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isSummarySticky, setIsSummarySticky] = useState<boolean>(false);
  const [coAmount, setCoAmount] = useState<number>(0);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [hasForcedOptionsBeenFlagged, setHasForcedOptionsBeenFlagged] =
    useState<boolean>(false);
  const templatesAllowed = useAccessControl("OptionTemplate", "GetTemplates");
  const RetrieveAllOptionsAccess = useAccessControl("Job", "GetAllOptions");
  const RetrieveOptionsCategoryAccess = useAccessControl(
    "Option",
    "GetOptionsCategory"
  );
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const GetIntentionAccess = useAccessControl("Intention", "GetIntention");
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const ConverIntentionAccess = useAccessControl("Intention", "ConvertToJob");

  const navigate = useNavigate();

  const handleDownloadItems = () => {
    setDownloading(true);

    if (intentionId) {
      DownloadChangeOrderAccess &&
        downloadChangeOrderPDF(
          { changeOrderId: intentionId },
          async (res: {
            fileData: "string";
            contentType: "string";
            documentName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              documentName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement("a");

              // Set the download attribute with a filename
              link.download = documentName;

              // Set the href to the blob URL
              link.href = blobUrl;

              // Append the link to the document body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean-up: remove the link from the document
              document.body.removeChild(link);
            }

            downloadPDF(res.documentName, res.contentType, res.fileData);
            setDownloading(false);
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
            setDownloading(false);
          }
        );
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    setIsChangeOrderLoaded(false);
    dispatch({ type: "ResetChangeOrder" });
    /*if (state.changeOrder.selectedCO) {
      setIsChangeOrderLoaded(true);
      dispatch({
        type: "Navbar",
        payload: { title: "Submitted Change Order" },
      });
    }*/

    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      state.changeOrder.status === "clear" ||
      state.changeOrder.status === "blocked"
    ) {
      setHasForcedOptionsBeenFlagged(false);
    }
      
    if (state.changeOrder.status === "clear") retrieveIntentionData();
    if (state.changeOrder.status === "editing") {
      const listenersAreSet =
        window.onbeforeunload !== null ||
        window.onpopstate !== null ||
        window.onunload !== null;

      // If not, add event listeners
      if (listenersAreSet) {
        removeListeners();
      }
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("popstate", handleBeforeUnload);
      window.addEventListener("unload", handleBeforeUnload);

      // Cleanup when component is unmounted:
      return () => {
        removeListeners();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.status, GetIntentionAccess]);

  useEffect(() => {
    // Updates Buyer legal name if it's different on SalesForce
    if (isChangeOrderLoaded) {
      if (
        FindCustomerByNameFromSFAccess &&
        state.changeOrder.customerBuyer?.salesforceAccountId &&
        state.changeOrder.customerBuyer?.salesforceAccountId !== "Spec"
      ) {
        findBuyerBySfAccountId(
          {
            salesforceAccountId:
              state.changeOrder.customerBuyer?.salesforceAccountId,
            communityName: state.changeOrder.community.name,
          },
          (res: any) => {
            const matchingBuyers = res.data;
            if (
              matchingBuyers.length === 1 &&
              matchingBuyers[0].contractBuyerNames !==
                state.changeOrder.customerBuyer.contractBuyerNames
            ) {
              const payload: convertIntentionType = {
                intention: intentionId as string,
                buyerChanges: {
                  salesforceAccountId: state.changeOrder.customerBuyer
                    ?.salesforceAccountId as string,
                  salesforceConnectionNumber: state.changeOrder.customerBuyer
                    ?.connectionNumber as string,
                  buyerFirstName: state.changeOrder.customerBuyer
                    ?.buyerFirstName as string,
                  buyerLastName: state.changeOrder.customerBuyer
                    ?.buyerLastName as string,
                  buyerLegalName: matchingBuyers[0]
                    .contractBuyerNames as string,
                },
              };
              ConverIntentionAccess &&
                convertIntention(
                  payload,
                  (res: any) => {
                    dispatch({
                      type: "ChangeOrder",
                      payload: {
                        customerBuyer: matchingBuyers[0],
                      },
                      source: "update Buyer Legal name",
                    });
                    dispatch({
                      type: "Snackbar",
                      payload: {
                        show: true,
                        message:
                          "The Legal Name has been updated in Salesforce and is reflected in Change Order.",
                        severity: "success",
                      },
                    });
                  },
                  (err: any) => {
                    dispatch({
                      type: "Snackbar",
                      payload: {
                        show: true,
                        message: err.response.data.Message,
                        severity: "warning",
                      },
                    });
                  }
                );
            }
          },
          (err: any) => {
            const errorTypes = Object.getOwnPropertyNames(
              err.response.data.errors
            );
            errorTypes.forEach((errorType: any) => {
              err.response.data.errors[errorType].forEach((errMsg: string) => {
                dispatch(
                  dispatchError({
                    message: err.message,
                    statusText: err.response.statusText,
                    title: err.response.data.title,
                    status: err.response.status,
                    detail: errMsg,
                    data: err.response.data,
                  })
                );
              });
            });
          }
        );
      }

      if (state.changeOrder.address.jobID) {
        JobHasSubmittedIntention(
          {
            jobId: state.changeOrder.address.jobID,
          },
          (res: any) => {
            const payload = { ...state.changeOrder.address };
            payload.jobHasSubmittedCOs = res.data;
            dispatch({
              type: "ChangeOrder",
              payload: {
                address: payload,
              },
              source: "Job has been submitted validation",
            });
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
          }
        );

        JobHasSubmittedBasePlan(
          { jobId: state.changeOrder.address.jobID },
          (res: any) => {
            dispatch({
              type: "ChangeOrder",
              payload: {
                hasSubmittedBasePlan: res.data
              },
              source: "updating of Job has submitted BasePlan"
            });
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
          }
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChangeOrderLoaded,
    ConverIntentionAccess,
    FindCustomerByNameFromSFAccess,
  ]);

  useEffect(() => {
    if (lastSaved === null && lastSaved !== state.changeOrder.lastSaved) {
      retrieveIntentionData();
      setLastSaved(state.changeOrder.lastSaved);
    }
    if (lastSaved !== null && lastSaved !== state.changeOrder.lastSaved) {
      setLastSaved(state.changeOrder.lastSaved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.lastSaved]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 70;
      if (window.scrollY > scrollThreshold && !isSummarySticky) {
        setIsSummarySticky(true);
      } else if (window.scrollY <= scrollThreshold && isSummarySticky) {
        setIsSummarySticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSummarySticky]);

  useEffect(() => {
    console.log("ChangeOrder", state.changeOrder);
    /*if(state.changeOrder.customerBuyer)
      handleBuyerSearch(`${state.changeOrder.customerBuyer?.buyerFirstName} ${state.changeOrder.customerBuyer?.buyerLastName}`);*/
    RetrieveOptionsCategoryAccess && retrieveChangeOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.changeOrder.plan,
    state.changeOrder.community,
    state.changeOrder.elevation,
    state.changeOrder.address?.projectID,
    RetrieveOptionsCategoryAccess,
  ]);

  useEffect(() => {
    if (state.changeOrder.cmName && state.constructionManagers.length > 0) {
      const currentCM = state.constructionManagers.find(
        (option: ConstructionManagerTypes) =>
          option.cmName === state.changeOrder.cmName
      );
      if (currentCM) setConstructionManager(currentCM);
    } else setConstructionManager(null);
  }, [state.changeOrder.cmName, state.constructionManagers]);

  useEffect(() => {
    setCoAmount(
      state.changeOrder.pendingItems
        .map((item: LineItems) => Number(item.quantity) * item.unitPrice)
        .reduce((acc: any, curr: any) => acc + curr, 0)
    );
    if(state.changeOrder.hasSubmittedBasePlan &&
      state.changeOrder.pendingItems
        .some((item:OptionTypes) => item.category === "Plan and Elevation")
    ) {
      const newItems = state.changeOrder.pendingItems.filter(
        (item: OptionTypes) => item.category !== "Plan and Elevation"
      );
      dispatch({
        type: "ChangeOrderPending",
        payload: newItems,
        source: "Delete Plan & Elevation not allowed item",
      });
      dispatch({
        type: "Snackbar",
        payload: {
          duration: 5000,
          show: true,
          message: (
            <div
              style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
            >
              {state.changeOrder.address.address} ({state.changeOrder.address.projectNumber}-{state.changeOrder.address.jobNumber})
              already has a submitted base plan elevation.
              Use the action menu to change Plan or Elevation for an existing job.
              <Button
                onClick={() => {
                  window.open(
                    `./job-summary?addressid=${intentionId}`
                  );
                }}
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "white",
                  marginLeft: "8px",
                }}
              >
                Open Job Summary
              </Button>
            </div>
          ),
          severity: "warning",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.pendingItems, state.changeOrder.hasSubmittedBasePlan]);

  useEffect(() => {
    if (!hasForcedOptionsBeenFlagged) {
      if (
        state.changeOrder.status === "saved" &&
        state.availableOptions.length > 0
      ) {
        if (
          state.changeOrder.pendingItems.length > 0 &&
          state.availableOptions.length > 0
        ) {
          const newPendingItems = state.changeOrder.pendingItems.map(
            (item: OptionTypes) => {
              const resultItem = { ...item };
              const selectedOption = state.availableOptions.find(
                (option: OptionTypes) => option.description === item.description
              );
              if (selectedOption) {
                resultItem.forcedOption = selectedOption.forcedOption;
                resultItem.manualPrice = selectedOption.manualPrice;
              }
              return resultItem;
            }
          );
          dispatch({
            type: "ChangeOrderPending",
            payload: newPendingItems,
            preventStatusChange: true,
            source: "ForcedOptions validation",
          });
        }
        setHasForcedOptionsBeenFlagged(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.pendingItems, state.availableOptions]);

  // Prevent exiting without confirming:
  const handleBeforeUnload = (event: any) => {
    if (
      state.changeOrder.lastSaved !== null ||
      state.changeOrder.status === "editing"
    ) {
      event.preventDefault();
    }
  };

  const removeListeners = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.removeEventListener("popstate", handleBeforeUnload);
    window.removeEventListener("unload", handleBeforeUnload);
  };

  const retrieveIntentionData = () => {
    setIsChangeOrderLoaded(false);
    if (intentionId && GetIntentionAccess) {
      getIntention(
        intentionId,
        (res: any) => {
          console.log(res);
          setHasForcedOptionsBeenFlagged(false);
          if (res.data.submittedAt !== null) {
            // Redirect to read-only view if intention has been submitted already
            navigate(`/view?intentionId=${intentionId}`);
          }
          const payload = { ...state.changeOrder };
          payload.address = {
            address: res.data.sites[0]?.address,
            community: res.data.sites[0]?.communityName,
            jobID: res.data.sites[0]?.jobID,
            jobNumber: res.data.sites[0]?.jobNumber,
            planElevationId: res.data.sites[0]?.planElevationID,
            planElevationNumber:
              res.data.sites[0]?.planElevationNumber,
            planId: res.data.sites[0]?.planID,
            planNumber: res.data.sites[0]?.planNumber,
            projectID: res.data.sites[0]?.projectID,
            projectNumber: res.data.sites[0]?.projectNumber,
          };
          payload.cmId = res.data.sites[0]?.cmId;
          payload.cmName = res.data.sites[0]?.cmName;
          payload.community = {
            name: res.data.sites[0]?.communityName,
            projectId: res.data.sites[0]?.projectID,
            projectNumber: res.data.sites[0]?.projectNumber,
          };
          if (res.data.submittedAt === null) {
            const currentIntention = res.data.intentionNumbers.find(
              (intention: any) => intention.isCurrent
            );
            payload.currentIntention = currentIntention;
          }
          payload.customerBuyer = {
            buyerFirstName: res.data.buyers[0]?.firstName,
            buyerLastName: res.data.buyers[0]?.lastName,
            salesforceAccountId:
              res.data.buyers[0]?.salesforceAccountId,
            contractBuyerNames: res.data.buyers[0]?.legalName || null,
            connectionNumber:
              res.data.buyers[0]?.salesforceConnectionNumber,
          };
          payload.plan = {
            abbreviation: "",
            community: res.data.sites[0]?.communityName,
            description: "",
            planElevationID:
              res.data.sites[0]?.planElevationID?.toString() || "",
            planElevationNumber:
              res.data.sites[0]?.planElevationNumber,
            planId: res.data.sites[0]?.planID?.toString() || "",
            planNumber: res.data.sites[0]?.planNumber,
            projectID: res.data.sites[0]?.projectID,
            projectNumber: res.data.sites[0]?.projectNumber,
          };
          payload.keyword = res.data.keyword;
          payload.lot = res.data.sites[0]?.lot || "";
          payload.elevation = {
            planElevationID: res.data.sites[0]?.planElevationID,
            planElevationNumber:
              res.data.sites[0]?.planElevationNumber,
          };
          payload.planElevationOverride =
            res.data.sites[0]?.planElevationOverride;
          let currentSort: number;
          let currentHighlandCategory: string | null = null;
          payload.pendingItems = res.data.intentionOptions
            ? res.data.intentionOptions
                .sort((objA: any, objB: any) => {
                  // which highlandCategory this item belongs to?
                  const hcA = state.highlandCategories.find(
                    (hc: HighlandCategoryOption) =>
                      hc.key === objA.highlandCategory
                  );
                  const hcB = state.highlandCategories.find(
                    (hc: HighlandCategoryOption) =>
                      hc.key === objB.highlandCategory
                  );
                  if (hcA.sort !== hcB.sort) return hcA.sort - hcB.sort;
                  if (objA.sortOrder !== objB.sortOrder)
                    return objA.sortOrder - objB.sortOrder;
                  if (objA.category !== objB.category)
                    return objA.category.localeCompare(objB.category);
                  return objA.description.localeCompare(objB.description);
                })
                .map((item: any, itemIx: number) => {
                  const newItem = { ...item };
                  newItem.highlandCategory = state.highlandCategories.find(
                    (category: HighlandCategoryOption) =>
                      category.key === item.highlandCategory
                  );
                  newItem.forcedOption = false;
                  newItem.isNew = false;
                  newItem.isRequired = false;
                  newItem.manualPrice = item.manualPrice || false;
                  newItem.option = item.category;
                  currentSort =
                    itemIx === 0 ||
                    item.highlandCategory !== currentHighlandCategory
                      ? 1
                      : currentSort + 1;
                  newItem.sortOrder = currentSort;
                  currentHighlandCategory = item.highlandCategory;
                  return newItem;
                })
            : [];
          if (payload.pendingItems.length > 0) {
            const lastSaved = new Date(res.data.createdAt);
            payload.lastSaved = lastSaved;
            setLastSaved(lastSaved);
          }
          payload.previousContractPrice =
            res.data.previousContractPrice || 0;
          payload.stage = res.data.sites[0]?.stage;
          payload.status = "saved";

          // Building used HighlandCategories out of data:
          const pendingCategories: HighlandCategoryOption[] = [];
          if (res.data.intentionOptions) {
            res.data.intentionOptions.forEach((item: any) => {
              if (
                item.highlandCategory !== null &&
                !pendingCategories.some(
                  (category: HighlandCategoryOption) =>
                    category.key === item.highlandCategory
                )
              ) {
                pendingCategories.push(
                  state.highlandCategories.find(
                    (category: HighlandCategoryOption) =>
                      category.key === item.highlandCategory
                  )
                );
              }
            });
            pendingCategories.sort(
              (a: HighlandCategoryOption, b: HighlandCategoryOption) => {
                if (a.sort < b.sort) return -1;
                if (a.sort > b.sort) return 1;
                return 0;
              }
            );
          }
          payload.pendingCategories = pendingCategories;
          dispatch({
            type: "ChangeOrder",
            payload,
            source: "Edit page load",
          });

          //
          if (res.data.submittedAt === null) {
            dispatch({
              type: "Navbar",
              payload: {
                title: "Pending Change Order : ",
              },
            });
          } else {
            dispatch({
              type: "Navbar",
              payload: { title: "Submitted Change Order" },
            });
          }
          setIsChangeOrderLoaded(true);
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));

          // Redirect back to home, since this one doesn't work:
          navigate("/");
        }
      );
      getLastSubmittedCO(
        { intentionId },
        (res: any) => {
          setPreviousCO(res.data === "No Submitted CO" ? "N/A" : res.data);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );

      getIntentionUpdates(
        intentionId,
        (res:any) => {
          console.log(res);
          // display snackbar messages.
          if (res.data && res.data.length > 0) {
            let updateIx = 0;
            const displayUpdate = () => {
              if (updateIx < res.data.length) {
                dispatch({
                  type: "Snackbar",
                  payload: {
                    show: true,
                    message: res.data[updateIx].message,
                    severity: "success",
                  },
                });
                updateIx++;
                setTimeout(displayUpdate, 3000);
              }
            };
            displayUpdate();
          }
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );
    }
  };

  const retrieveChangeOrderData = () => {
    console.log("ChangeOrder", state.changeOrder);
    if (
      state.changeOrder.plan?.planId &&
      state.changeOrder.community.projectNumber &&
      state.changeOrder.elevation.planElevationID
    ) {
      retrieveOptionCategories(
        {
          PlanId: state.changeOrder.plan.planId,
          ProjectId: state.changeOrder.community.projectId,
          PlanElevationId: state.changeOrder.elevation.planElevationID,
        },
        (res: any) => {
          // build the category catalog:
          const newOptionsCategory = res.data.map((obj: OptionsCategory) => ({
            ...obj,
          }));
          state.highlandCategories.forEach(
            (highlandCategory: HighlandCategoryOption) => {
              newOptionsCategory.push(
                {
                  option: "Manual Option",
                  highlandCategory: highlandCategory.key,
                  category: "Manual Option",
                },
                {
                  option: "Formatting Separator",
                  highlandCategory: highlandCategory.key,
                  category: "Formatting Separator",
                }
              );
            }
          );
          dispatch({
            type: "AvailableOptionCategories",
            payload: newOptionsCategory,
          });
        },
        (err: any) =>
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          )
      );

      RetrieveAllOptionsAccess &&
        retrieveAllOptions(
          {
            PlanId: state.changeOrder.plan.planId,
            ProjectId: state.changeOrder.community.projectId,
            PlanElevationId: state.changeOrder.elevation.planElevationID,
          },
          (res: any) => {
            const newOptions = res.data;
            newOptions.sort((a: any, b: any) => {
              const itemDescA =
                a.description.split(":").length < 2
                  ? a.description.trim()
                  : a.description.split(":")[1].trim();
              const itemDescB =
                b.description.split(":").length < 2
                  ? b.description.trim()
                  : b.description.split(":")[1].trim();
              if (itemDescA < itemDescB) return -1;
              else if (itemDescA > itemDescB) return 1;
              return 0;
            });
            state.highlandCategories.forEach(
              (highlandCategory: HighlandCategoryOption) => {
                newOptions.push(
                  {
                    option: "Manual Option",
                    description: "",
                    highlandCategory: highlandCategory.key,
                    category: "Manual Option", //highlandCategory.key.toUpperCase(),
                    optionAbbreviation: "Manual Option",
                    optionCategoryID: 0,
                    optionCode: "",
                    optionId: 0,
                    salesPrice: "",
                    salesPriceControlID: 0,
                  },
                  {
                    option: "Formatting Separator",
                    description: "",
                    highlandCategory: highlandCategory.key,
                    category: "Formatting Separator",
                    optionAbbreviation: "Formatting Separator",
                    optionCategoryId: 0,
                    optionCode: "",
                    optionId: 0,
                    salesPrice: "",
                    salesPriceControlID: 0,
                  }
                );
              }
            );
            dispatch({
              type: "AvailableOptions",
              payload: newOptions,
            });
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            )
        );

      if (templatesAllowed) {
        getTemplates(
          {
            projectId: state.changeOrder.plan.projectID,
            planId: state.changeOrder.plan.planId,
          },
          (res: any) => {
            const templatesData = res.data.optionTemplates
              ? res.data.optionTemplates.map((template: any) => {
                  return {
                    id: template.id,
                    name: template.templateName,
                    createdAt: new Date(template.createdAt),
                    createdBy: template.createdByName,
                  };
                })
              : [];
            dispatch({
              type: "AvailableTemplates",
              payload: templatesData,
            });
          },
          (res: any) =>
            dispatch({
              type: "Snackbar",
              payload: {
                show: true,
                message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
                severity: "error",
              },
            })
        );
      }
    }
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          my: mainTheme.spacing(2),
        }}
        component="main"
        maxWidth={false}
      >
        <CssBaseline />
        <Stack
          className={`summaries-wrapper${isSummarySticky ? " sticky" : ""}`}
          sx={{
            width: "100%",
            height: "100%",
          }}
          gap={2}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Stack
            alignItems={"center"}
            alignContent={"center"}
            gap={2}
            flexDirection={"row"}
            sx={{
              alignItems: "top",
              border: 1,
              borderColor: (mainTheme) => mainTheme.palette.grey[300],
              borderTop: 4,
              borderRadius: "10px",
              width: "100%",
              borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
              justifyContent: { xs: "center", md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
              maxWidth: { xs: "100%", lg: "60%" },
              py: 0.5,
              px: 2,
            }}
          >
            <div className="edit-summary-details">
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Buyer:</strong>
              </p>
              <div className="edit-summary-details--info">
                {isChangeOrderLoaded ? (
                  <ValidatedInput
                    className="edit-summary-details--editor"
                    //dropdownData={state.availableBuyerNames || ""}
                    //editTitle="Assign a buyer from SalesForce"
                    inputVariant="standard"
                    isVerified={
                      !!state.changeOrder.customerBuyer?.salesforceAccountId
                    }
                    mode="readonly"
                    nonVerifiedTitle="This C.O. doesn't has a buyer assigned yet"
                    /*onChange={(newBuyer:any) => {
                      const payload: convertIntentionType = {
                        intention: intentionId as string,
                        buyerChanges: {
                          salesforceAccountId: newBuyer.salesforceAccountId as string,
                          salesforceConnectionNumber: newBuyer.connectionNumber as string,
                          buyerFirstName: newBuyer.buyerFirstName as string,
                          buyerLastName: newBuyer.buyerLastName as string,
                          buyerLegalName: newBuyer.contractBuyerNames as string,
                        },
                      };
                      convertIntention(
                        payload,
                        (res: any) => {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              customerBuyer: newBuyer,
                              lastSaved: new Date()
                            },
                            source: "convertIntention"
                          });
                        },
                        (err: any) => {
                          console.log(err);
                        }
                      );
                    }}
                    onDropdownType={handleBuyerSearch}*/
                    optionLabel={(option) => {
                      if (option === null) return "";
                      if (option.contractBuyerNames === "Spec") {
                        return `${option.contractBuyerNames}`;
                      } else if (
                        option.contractBuyerNames &&
                        option.connectionNumber
                      ) {
                        return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
                      } else if (typeof option === "string") {
                        return option;
                      }
                      return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
                    }}
                    type="autocomplete" // switch to autocomplete if the editor function gets activated
                    title={state.changeOrder.customerBuyer?.contractBuyerNames}
                    value={state.changeOrder.customerBuyer}
                    verify={true}
                    verifiedTitle="Buyer in SalesForce"
                    //saveTitle="Assign this Buyer to the C.O."
                  />
                ) : (
                  <Skeleton variant="text" height={24} width={160} />
                )}
              </div>
            </div>
            <div className="edit-summary-details">
              <p className="edit-summary-details--title">Community</p>
              <p className="edit-summary-details--info">
                {state.changeOrder.community?.name || (
                  <Skeleton variant="text" height={24} width={160} />
                )}
              </p>
            </div>
            <div className="edit-summary-details">
              <p className="edit-summary-details--title">Address</p>
              <div className="edit-summary-details--info">
                {isChangeOrderLoaded ? (
                  <ValidatedInput
                    className="edit-summary-details--editor"
                    dropdownData={state.availableAddresses}
                    editTitle="Assign an address from BRIX"
                    finalLabel={(value) => {
                      if (value === null) return "";
                      if (typeof value === "string") return value;
                      return value.address;
                    }}
                    inputVariant="standard"
                    isVerified={!!state.changeOrder.address?.jobID}
                    mode={
                      state.changeOrder.address?.jobID ? "readonly" : "editor"
                    }
                    nonVerifiedTitle="this C.O. doesn't have assigned an address from BRIX yet"
                    onChange={(newAddress) => {
                      convertToJob(
                        {
                          intention: intentionId as string,
                          jobId: newAddress.jobID ? newAddress.jobID : 0,
                          jobNumber: newAddress.jobNumber,
                        },
                        (res: any) => {
                          if (res.data.success === false) {
                            dispatch({
                              type: "Snackbar",
                              payload: {
                                show: true,
                                message: (
                                  <span
                                    style={{
                                      alignItems: "center",
                                      display: "inline-flex",
                                    }}
                                  >
                                    This Address has related Change Order(s). Go
                                    to Job Summary Page.
                                    <Button
                                      onClick={() => {
                                        window.open(
                                          `./job-summary?addressid=${res.data.existingJobIntentionId}`
                                        );
                                      }}
                                      variant="outlined"
                                      sx={{
                                        color: "white",
                                        borderColor: "white",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      Open
                                    </Button>
                                  </span>
                                ),
                                severity: "warning",
                              },
                            });
                          } else {
                            dispatch({
                              type: "ChangeOrder",
                              payload: {
                                address: newAddress,
                                lastSaved: new Date(),
                              },
                              source: "converToJob",
                            });
                          }
                        },
                        (err: any) => {
                          dispatch(
                            dispatchError({
                              detail: err.response.data.Message,
                              message: err.message,
                              status: err.response.data.StatusCode,
                              statusText: err.resopnse.request.statusText,
                            })
                          );
                        }
                      );
                    }}
                    optionDisabled={(option: any) =>
                      (option.planId &&
                        option.planId !== state.changeOrder.plan?.planId) ||
                      (option.planElevationId &&
                        option.planElevationId !==
                          state.changeOrder.plan?.planElevationID)
                    }
                    optionLabel={(option) => {
                      if (typeof option === "string") return option;
                      if (option.address === "TBD / Preliminary")
                        return option.address;
                      return option && option.address !== null
                        ? `${option.address} ${
                            option.planNumber === null
                              ? ""
                              : `(${option.planNumber} / ${option.planElevationNumber})`
                          }`
                        : "";
                    }}
                    type="autocomplete"
                    title=""
                    value={state.changeOrder.address}
                    verify={true}
                    verifiedTitle="Address in BRIX"
                    saveTitle="Assign this Address to the C.O."
                  />
                ) : (
                  <Skeleton variant="text" height={24} width={160} />
                )}
              </div>
            </div>
            <div className="edit-summary-details">
              <p className="edit-summary-details--title">Plan / Elevation</p>
              <p className="edit-summary-details--info">
                {state.changeOrder.plan?.planId ? (
                  <>
                    {state.changeOrder.plan?.planNumber
                      ? state.changeOrder.plan?.planNumber
                      : "--"}
                  </>
                ) : (
                  <Skeleton variant="text" height={24} width={40} />
                )}
                &nbsp;/&nbsp;
                {state.changeOrder.elevation?.planElevationID ? (
                  <>
                    {state.changeOrder.elevation.planElevationNumber
                      ? state.changeOrder.elevation.planElevationNumber
                      : "--"}
                  </>
                ) : (
                  <Skeleton variant="text" height={24} width={60} />
                )}
              </p>
            </div>
            <div className="edit-summary-details">
              <p className="edit-summary-details--title">Stage / CM</p>
              <div className="edit-summary-details--info">
                {isChangeOrderLoaded ? (
                  <>
                    {state.changeOrder.stage === null
                      ? ""
                      : state.changeOrder.stage}
                  </>
                ) : (
                  <Skeleton variant="text" height={24} width={40} />
                )}
                &nbsp;/&nbsp;
                {isChangeOrderLoaded && state.constructionManagers ? (
                  <ValidatedInput
                    className="edit-summary-details--editor"
                    dropdownData={state.constructionManagers}
                    editTitle="Assign a Construction Manager"
                    inputVariant="standard"
                    isVerified={constructionManager !== null}
                    mode={
                      state.changeOrder.address?.address ===
                        "TBD / Preliminary" ||
                      state.changeOrder.address?.jobHasSubmittedCOs
                        ? "readonly"
                        : "editor"
                    }
                    //nonVerifiedTitle="this C.O. doesn't have assigned a Construction Manager yet"
                    onChange={(newCM) => {
                      upsertConstructionManager(
                        {
                          ChangeOrderId: intentionId || "",
                          CMName: newCM?.cmName,
                          SuperintendentID: newCM?.cmId,
                        },
                        (res: any) => {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              cmId: newCM?.cmId,
                              cmName: newCM?.cmName,
                              lastSaved: new Date(),
                            },
                            source: "upsertConstructionManager",
                          });
                        },
                        (err: any) => dispatch(dispatchError(err.response.data))
                      );
                    }}
                    optionLabel={(option) => {
                      if (typeof option === "string") return option;
                      if (option === null) return "--";
                      return option.cmName;
                    }}
                    type="autocomplete"
                    value={constructionManager}
                    verify={false}
                    verifiedTitle="Construction Manager assigned"
                    saveTitle="Assign this Construction Manager to the C.O."
                  />
                ) : (
                  <Skeleton variant="text" height={24} width={60} />
                )}
              </div>
            </div>
            <Stack className="edit-summary-cta-wrapper" direction="row">
              {isChangeOrderLoaded ? (
                <>
                  {state.changeOrder.buyerType !== "spec" && (
                    <>
                      <PrepareChangeOrder
                        modalTitle={"Edit Change Order"}
                        mode="edit"
                        buttonTitle={"Edit"}
                        createButtonTitle={"Save"}
                        intentionId={intentionId as string}
                        redirect={false}
                      />
                    </>
                  )}
                  <span>
                    <Button
                      disabled={
                        downloading ||
                        state.changeOrder.pendingItems.filter(
                          (item: OptionTypes) => !item.isNew
                        ).length === 0
                      }
                      onClick={handleDownloadItems}
                      variant="contained"
                    >
                      <Download />
                    </Button>
                  </span>
                  <PDFPreview
                    disabled={
                      downloading ||
                      state.changeOrder.pendingItems.filter(
                        (item: OptionTypes) => !item.isNew
                      ).length === 0
                    }
                    mode="printPreview"
                    printFlow={handleDownloadItems}
                    title="Print Preview"
                  />
                </>
              ) : (
                <>
                  <Skeleton
                    variant="rounded"
                    height={40}
                    width={32}
                    sx={{ marginRight: "8px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    height={40}
                    width={32}
                    sx={{ marginRight: "8px" }}
                  />
                  <Skeleton variant="rounded" height={40} width={32} />
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            alignItems={"center"}
            alignContent={"center"}
            gap={2}
            flexDirection={"row"}
            sx={{
              width: "100%",
              alignItems: "center",
              border: 1,
              borderColor: (mainTheme) => mainTheme.palette.grey[300],
              borderTop: 4,
              borderRadius: "10px",
              borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
              justifyContent: { xs: "center", md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
              maxWidth: { xs: "100%", lg: "35%" },
              py: 0.5,
              px: 2,
            }}
          >
            <div>
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Last Submitted</strong>
              </p>
              <p style={{ margin: "0 auto", textAlign: "right" }}>
                {isChangeOrderLoaded && previousCO ? (
                  previousCO
                ) : (
                  <Skeleton variant="text" height={24} width={50} />
                )}
              </p>
            </div>
            <div
              style={{
                backgroundColor: "#e0e0e0",
                width: "1px",
                height: "56px",
              }}
            />
            <div>
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>C.O. Amount</strong>
              </p>
              <p style={{ margin: "0 auto", textAlign: "right" }}>
                {isChangeOrderLoaded ? (
                  `$ ${numberWithCommas(coAmount)}`
                ) : (
                  <Skeleton variant="text" height={24} width={100} />
                )}
              </p>
            </div>
            <div>
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Previous Amount</strong>
              </p>
              <p style={{ margin: "0 auto", textAlign: "right" }}>
                {isChangeOrderLoaded ? (
                  `$ ${numberWithCommas(
                    state.changeOrder.previousContractPrice
                  )}`
                ) : (
                  <Skeleton variant="text" height={24} width={100} />
                )}
              </p>
            </div>
            <div>
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>New Amount</strong>
              </p>
              <p style={{ margin: "0 auto", textAlign: "right" }}>
                {isChangeOrderLoaded ? (
                  `$ ${numberWithCommas(
                    state.changeOrder.previousContractPrice + coAmount
                  )}`
                ) : (
                  <Skeleton variant="text" height={24} width={100} />
                )}
              </p>
            </div>
          </Stack>
        </Stack>
        {isChangeOrderLoaded &&
        state.availableOptions.length > 0 &&
        state.availableOptionCategories.length > 0 ? (
          <div className={`pending-main${isSummarySticky ? " sticky" : ""}`}>
            <OptionCategories mode="edit" />
          </div>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={40}
              sx={{ marginBottom: "4px", marginTop: "40px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={46}
              sx={{ marginBottom: "4px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={46}
              sx={{ marginBottom: "4px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={46}
              sx={{ marginBottom: "4px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={55}
              sx={{ marginBottom: "4px" }}
            />
          </>
        )}
        <EditFooter />
        { state.clDialogs.length > 0 && 
          <Dialog open={true}>
            <DialogContent>
              <Stack direction="row">
                { state.clDialogs[0].img && <img src={state.clDialogs[0].img} style={{ height: "140px", width: "auto"}} /> }
                <div style={{ paddingLeft: "10px"}}>
                  {state.clDialogs[0].body}
                </div>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  const newClDialogs = [...state.clDialogs];
                  newClDialogs.shift();
                  dispatch({
                    type: "ClDialogs",
                    payload: newClDialogs
                  });
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        }
      </Container>
    </ThemeProvider>
  );
}

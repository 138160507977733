import React, { useContext, useState } from "react";
import { UIContext } from "../../../providers/UIProvider";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { allChangeOrderOptions, GetSubmittedBasePlan } from "../../../apiCalls";
import { dispatchError } from "../../../common/fx";
import {
  ArrowCircleDown,
  ArrowCircleUp,
  ArrowRightAltOutlined,
} from "@mui/icons-material";
import { numberWithCommas } from "../../../utils/formatMoney";
import { gt, lt } from "lodash";
import useAccessControl from "../../../hooks/useAccessControl";

type Props = {
  newHomeInformation: any;
  loadingCalculation: any;
  setPriceDifference: any;
  priceDifference: any;
  currentPrice: any;
  adjustPrice: number;
  updateElevationMode: boolean;
};

const Step2 = ({
  newHomeInformation,
  adjustPrice,
  loadingCalculation,
  setPriceDifference,
  priceDifference,
  updateElevationMode,
  currentPrice,
}: Props) => {
  const checkNumber = (
    number: number,
    type: "positive" | "negative"
  ): boolean => {
    if (number === 0) return false; // handle zero case
    return type === "positive" ? gt(number, 0) : lt(number, 0);
  };
  const [state, dispatch]: any = useContext(UIContext);

  const calculatePriceDifference = (
    currentPrice: number,
    newPrice: number | string,
    adjustPrice: number
  ): string => {
    const difference =
      Number(newPrice) - Number(currentPrice) + Number(adjustPrice);
    setPriceDifference(difference.toString());
    return difference.toString();
  };

  React.useEffect(() => {
    calculatePriceDifference(
      currentPrice,
      newHomeInformation?.salesPrice,
      adjustPrice
    );

    return () => {
      setPriceDifference("");
    };
  }, [newHomeInformation, currentPrice, adjustPrice]);

  return (
    <Stack gap={2} direction={"column"}>
      <Stack justifyContent={"space-between"} direction={"row"}>
        <div>
          <Typography fontWeight={"bold"}>Current Plan / Elevation</Typography>
          <Typography>
            {state.selectedJob.planNumber} /{" "}
            {state.selectedJob.planElevationNumber}
          </Typography>
        </div>

        <ArrowRightAltOutlined
          sx={{
            fontSize: "50px",
          }}
        />

        <div>
          <Typography fontWeight={"bold"}>New Plan / Elevation</Typography>
          <Typography textAlign={"end"}>
            {newHomeInformation?.planNumber} /{" "}
            {newHomeInformation.planElevationNumber}
          </Typography>
        </div>
      </Stack>
      {!updateElevationMode && (
        <>
          <Divider orientation="horizontal" />

          <Stack justifyContent={"space-between"} direction={"row"}>
            <div>
              <Typography fontWeight={"bold"}>Current Price</Typography>
              {!loadingCalculation && (
                <Typography> ${numberWithCommas(currentPrice)}</Typography>
              )}
            </div>
            <div>
              <Typography fontWeight={"bold"}>Price Difference</Typography>

              <Stack
                gap={2}
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                {!loadingCalculation && (
                  <>
                    {Number(priceDifference) === 0 ? null : checkNumber(
                        Number(priceDifference),
                        "positive"
                      ) ? (
                      <ArrowCircleUp
                        sx={{ fontSize: "30px", color: "green" }}
                      />
                    ) : (
                      <ArrowCircleDown
                        sx={{ fontSize: "30px", color: "red" }}
                      />
                    )}
                    <Typography textAlign={"center"}>
                      ${numberWithCommas(priceDifference)}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack
                gap={2}
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                {Number(adjustPrice) !== 0 && (
                  <Typography variant="body2" textAlign={"center"}>
                    ( $
                    {numberWithCommas(
                      Number(newHomeInformation?.salesPrice) -
                        Number(currentPrice)
                    )}{" "}
                    + ${numberWithCommas(Number(adjustPrice))})
                  </Typography>
                )}
              </Stack>
            </div>
            <div>
              <Typography fontWeight={"bold"}>New Price</Typography>
              {!loadingCalculation && (
                <Typography textAlign={"end"}>
                  $
                  {numberWithCommas(
                    newHomeInformation?.salesPrice + Number(adjustPrice)
                  )}
                </Typography>
              )}
            </div>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Step2;

import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import { Button, Skeleton, Stack } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import ValidatedInput from "../../components/ValidatedInput";
import {
  OptionTypes,
  UIContext,
  UIState,
  ConstructionManagerTypes,
} from "../../providers/UIProvider";
import {
  convertToJob,
  downloadChangeOrderPDF,
  upsertConstructionManager
} from "../../apiCalls";
import PrepareChangeOrder from "../../modals/PrepareChangeOrder/PrepareChangeOrder";
import useAccessControl from "../../hooks/useAccessControl";
import PDFPreview from "../../modals/PDFPreview";

export default function COHeader(props:any) {
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  let intentionId = searchParams.get("intentionId");
  const [isChangeOrderLoaded, setIsChangeOrderLoaded] = useState<boolean>(false);
  const [constructionManager, setConstructionManager] =
    useState<ConstructionManagerTypes | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const DownloadChangeOrderAccess = useAccessControl("ChangeOrder", "DownloadPDF");

  useEffect(() => {
    if(state.changeOrder.status !== "clear") {
      setIsChangeOrderLoaded(true);
    }
  }, [state.changeOrder.status]);
  
  useEffect(() => {
    if (state.changeOrder.cmName && state.constructionManagers.length > 0) {
      const currentCM = state.constructionManagers.find(
        (option: ConstructionManagerTypes) =>
          option.cmName === state.changeOrder.cmName
      );
      if (currentCM) setConstructionManager(currentCM);
    } else setConstructionManager(null);
  }, [state.changeOrder.cmName, state.constructionManagers]);

  const handleDownloadItems = () => {
    setDownloading(true);

    if (intentionId) {
      DownloadChangeOrderAccess &&
        downloadChangeOrderPDF(
          { changeOrderId: intentionId },
          async (res: {
            fileData: "string";
            contentType: "string";
            documentName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              documentName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement("a");

              // Set the download attribute with a filename
              link.download = documentName;

              // Set the href to the blob URL
              link.href = blobUrl;

              // Append the link to the document body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean-up: remove the link from the document
              document.body.removeChild(link);
            }

            downloadPDF(res.documentName, res.contentType, res.fileData);
            setDownloading(false);
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
            setDownloading(false);
          }
        );
    }
  };

  return (
    <Stack
      alignItems={"center"}
      alignContent={"center"}
      gap={2}
      flexDirection={"row"}
      sx={{
        alignItems: "top",
        border: 1,
        borderColor: (mainTheme) => mainTheme.palette.grey[300],
        borderTop: 4,
        borderRadius: "10px",
        width: "100%",
        borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
        justifyContent: { xs: "center", md: "space-between" },
        flexDirection: { xs: "column", md: "row" },
        maxWidth: { xs: "100%", lg: "60%" },
        py: 0.5,
        px: 2,
      }}
    >
      <div className="edit-summary-details">
        <p style={{ margin: "0 auto .5rem" }}>
          <strong>Buyer:</strong>
        </p>
        <div className="edit-summary-details--info">
          {isChangeOrderLoaded ? (
            <ValidatedInput
              className="edit-summary-details--editor"
              //dropdownData={state.availableBuyerNames || ""}
              //editTitle="Assign a buyer from SalesForce"
              inputVariant="standard"
              isVerified={
                !!state.changeOrder.customerBuyer?.salesforceAccountId
              }
              mode="readonly"
              nonVerifiedTitle="This C.O. doesn't has a buyer assigned yet"
              /*onChange={(newBuyer:any) => {
                const payload: convertIntentionType = {
                  intention: intentionId as string,
                  buyerChanges: {
                    salesforceAccountId: newBuyer.salesforceAccountId as string,
                    salesforceConnectionNumber: newBuyer.connectionNumber as string,
                    buyerFirstName: newBuyer.buyerFirstName as string,
                    buyerLastName: newBuyer.buyerLastName as string,
                    buyerLegalName: newBuyer.contractBuyerNames as string,
                  },
                };
                convertIntention(
                  payload,
                  (res: any) => {
                    dispatch({
                      type: "ChangeOrder",
                      payload: {
                        customerBuyer: newBuyer,
                        lastSaved: new Date()
                      },
                      source: "convertIntention"
                    });
                  },
                  (err: any) => {
                    console.log(err);
                  }
                );
              }}
              onDropdownType={handleBuyerSearch}*/
              optionLabel={(option) => {
                if (option === null) return "";
                if (option.contractBuyerNames === "Spec") {
                  return `${option.contractBuyerNames}`;
                } else if (
                  option.contractBuyerNames &&
                  option.connectionNumber
                ) {
                  return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
                } else if (typeof option === "string") {
                  return option;
                }
                return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
              }}
              type="autocomplete" // switch to autocomplete if the editor function gets activated
              title={state.changeOrder.customerBuyer?.contractBuyerNames}
              value={state.changeOrder.customerBuyer}
              verify={true}
              verifiedTitle="Buyer in SalesForce"
              //saveTitle="Assign this Buyer to the C.O."
            />
          ) : (
            <Skeleton variant="text" height={24} width={160} />
          )}
        </div>
      </div>
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Community</p>
        <p className="edit-summary-details--info">
          {state.changeOrder.community?.name || (
            <Skeleton variant="text" height={24} width={160} />
          )}
        </p>
      </div>
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Address</p>
        <div className="edit-summary-details--info">
          {isChangeOrderLoaded ? (
            <ValidatedInput
              className="edit-summary-details--editor"
              dropdownData={state.availableAddresses}
              editTitle="Assign an address from BRIX"
              finalLabel={(value) => {
                if (value === null) return "";
                if (typeof value === "string") return value;
                return value.address;
              }}
              inputVariant="standard"
              isVerified={!!state.changeOrder.address?.jobID}
              mode={
                state.changeOrder.address?.jobID ? "readonly" : "editor"
              }
              nonVerifiedTitle="this C.O. doesn't have assigned an address from BRIX yet"
              onChange={(newAddress) => {
                convertToJob(
                  {
                    intention: intentionId as string,
                    jobId: newAddress.jobID ? newAddress.jobID : 0,
                    jobNumber: newAddress.jobNumber,
                  },
                  (res: any) => {
                    if (res.data.success === false) {
                      dispatch({
                        type: "Snackbar",
                        payload: {
                          show: true,
                          message: (
                            <span
                              style={{
                                alignItems: "center",
                                display: "inline-flex",
                              }}
                            >
                              This Address has related Change Order(s). Go
                              to Job Summary Page.
                              <Button
                                onClick={() => {
                                  window.open(
                                    `./job-summary?addressid=${res.data.existingJobIntentionId}`
                                  );
                                }}
                                variant="outlined"
                                sx={{
                                  color: "white",
                                  borderColor: "white",
                                  marginLeft: "8px",
                                }}
                              >
                                Open
                              </Button>
                            </span>
                          ),
                          severity: "warning",
                        },
                      });
                    } else {
                      dispatch({
                        type: "ChangeOrder",
                        payload: {
                          address: newAddress,
                          lastSaved: new Date(),
                        },
                        source: "converToJob",
                      });
                    }
                  },
                  (err: any) => {
                    dispatch(
                      dispatchError({
                        detail: err.response.data.Message,
                        message: err.message,
                        status: err.response.data.StatusCode,
                        statusText: err.resopnse.request.statusText,
                      })
                    );
                  }
                );
              }}
              optionDisabled={(option: any) =>
                (option.planId &&
                  option.planId !== state.changeOrder.plan?.planId) ||
                (option.planElevationId &&
                  option.planElevationId !==
                    state.changeOrder.plan?.planElevationID)
              }
              optionLabel={(option) => {
                if (typeof option === "string") return option;
                if (option.address === "TBD / Preliminary")
                  return option.address;
                return option && option.address !== null
                  ? `${option.address} ${
                      option.planNumber === null
                        ? ""
                        : `(${option.planNumber} / ${option.planElevationNumber})`
                    }`
                  : "";
              }}
              type="autocomplete"
              title=""
              value={state.changeOrder.address}
              verify={true}
              verifiedTitle="Address in BRIX"
              saveTitle="Assign this Address to the C.O."
            />
          ) : (
            <Skeleton variant="text" height={24} width={160} />
          )}
        </div>
      </div>
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Plan / Elevation</p>
        <p className="edit-summary-details--info">
          {state.changeOrder.plan?.planId ? (
            <>
              {state.changeOrder.plan?.planNumber
                ? state.changeOrder.plan?.planNumber
                : "--"}
            </>
          ) : (
            <Skeleton variant="text" height={24} width={40} />
          )}
          &nbsp;/&nbsp;
          {state.changeOrder.elevation?.planElevationID ? (
            <>
              {state.changeOrder.elevation.planElevationNumber
                ? state.changeOrder.elevation.planElevationNumber
                : "--"}
            </>
          ) : (
            <Skeleton variant="text" height={24} width={60} />
          )}
        </p>
      </div>
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Stage / CM</p>
        <div className="edit-summary-details--info">
          {isChangeOrderLoaded ? (
            <>
              {state.changeOrder.stage === null
                ? ""
                : state.changeOrder.stage}
            </>
          ) : (
            <Skeleton variant="text" height={24} width={40} />
          )}
          &nbsp;/&nbsp;
          {isChangeOrderLoaded && state.constructionManagers ? (
            <ValidatedInput
              className="edit-summary-details--editor"
              dropdownData={state.constructionManagers}
              editTitle="Assign a Construction Manager"
              inputVariant="standard"
              isVerified={constructionManager !== null}
              mode={
                state.changeOrder.address?.address ===
                  "TBD / Preliminary" ||
                state.changeOrder.address?.jobHasSubmittedCOs
                  ? "readonly"
                  : "editor"
              }
              //nonVerifiedTitle="this C.O. doesn't have assigned a Construction Manager yet"
              onChange={(newCM) => {
                upsertConstructionManager(
                  {
                    ChangeOrderId: intentionId || "",
                    CMName: newCM?.cmName,
                    SuperintendentID: newCM?.cmId,
                  },
                  (res: any) => {
                    dispatch({
                      type: "ChangeOrder",
                      payload: {
                        cmId: newCM?.cmId,
                        cmName: newCM?.cmName,
                        lastSaved: new Date(),
                      },
                      source: "upsertConstructionManager",
                    });
                  },
                  (err: any) => dispatch(dispatchError(err.response.data))
                );
              }}
              optionLabel={(option) => {
                if (typeof option === "string") return option;
                if (option === null) return "--";
                return option.cmName;
              }}
              type="autocomplete"
              value={constructionManager}
              verify={false}
              verifiedTitle="Construction Manager assigned"
              saveTitle="Assign this Construction Manager to the C.O."
            />
          ) : (
            <Skeleton variant="text" height={24} width={60} />
          )}
        </div>
      </div>
      <Stack className="edit-summary-cta-wrapper" direction="row">
        {isChangeOrderLoaded ? (
          <>
            {state.changeOrder.buyerType !== "spec" && (
              <>
                <PrepareChangeOrder
                  modalTitle={"Edit Change Order"}
                  mode="edit"
                  buttonTitle={"Edit"}
                  createButtonTitle={"Save"}
                  intentionId={intentionId as string}
                  redirect={false}
                />
              </>
            )}
            <span>
              <Button
                disabled={
                  downloading ||
                  state.changeOrder.pendingItems.filter(
                    (item: OptionTypes) => !item.isNew
                  ).length === 0
                }
                onClick={handleDownloadItems}
                variant="contained"
              >
                <Download />
              </Button>
            </span>
            <PDFPreview
              disabled={
                downloading ||
                state.changeOrder.pendingItems.filter(
                  (item: OptionTypes) => !item.isNew
                ).length === 0
              }
              mode="printPreview"
              printFlow={handleDownloadItems}
              title="Print Preview"
            />
          </>
        ) : (
          <>
            <Skeleton
              variant="rounded"
              height={40}
              width={32}
              sx={{ marginRight: "8px" }}
            />
            <Skeleton
              variant="rounded"
              height={40}
              width={32}
              sx={{ marginRight: "8px" }}
            />
            <Skeleton variant="rounded" height={40} width={32} />
          </>
        )}
      </Stack>
    </Stack>
  )

}
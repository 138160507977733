import React, { useContext } from "react";
import { Snackbar } from "@mui/material";
import { UIContext } from "../../providers/UIProvider";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref: any
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert: React.FC = () => {
  const [state, dispatch]: any = useContext(UIContext);

  const handleOpen = () => {
    dispatch({
      type: "Snackbar",
      payload: {
        show: false,
        message: "An Error Happened",
        severity: "error",
      },
    });
  };

  const getDuration = () => {
    return (
      state.snackbar.duration ??
      (state.snackbar.severity === "error"
        ? 5000
        : state.snackbar.severity === "success"
        ? 1000
        : 3000)
    );
  };

  return (
    <>
      {state.snackbar.show && (
        <Snackbar
          sx={{ "width": "100%" }}
          data-testid="snackbar"
          autoHideDuration={getDuration()}
          anchorOrigin={{
            vertical: state.snackbar.vertical ?? "top",
            horizontal: state.snackbar.horizontal ?? "center",
          }}
          open={state.snackbar.show}
          onClose={handleOpen}
          message={state.snackbar.message}
        >
          <Alert
            sx={{
              alignItems: "center",
              display: "flex",
              "& .MuiAlert-action": {
                padding: "0!important",
                marginLeft: "5px",
              },
            }}
            action={state.snackbar.action ?? ""}
            severity={
              state.snackbar.severity ? state.snackbar.severity : "error"
            }
          >
            {state.snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
export default SnackbarAlert;

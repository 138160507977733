import { useState, useContext, useEffect, useRef } from "react";
import { dispatchError } from "../../common/fx";
import ReusableDialog from "../../components/Dialog";
import { Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  AddressTypes,
  ChangeOrder,
  CommunityTypes,
  ConstructionManagerTypes,
  CustomerBuyerTypes,
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { useNavigate } from "react-router-dom";
import {
  findCustomerByNameSF,
  getAddress,
  getPlanElevations,
  getPlans,
  communityDropdownApi,
  convertIntention,
  convertIntentionType,
  convertToJob,
  getConstructionManagers,
  upsertConstructionManager,
} from "../../apiCalls";
import { debounce, isEmpty, isNil, isNull, uniqBy } from "lodash";
import useAccessControl from "../../hooks/useAccessControl";
import { filterOptions } from "../../utils/filterOptions";
type Props = {
  buttonTitle: string;
  createButtonTitle: string;
  modalTitle: string;
  mode: "create" | "edit";
  intentionId?: string;
  redirect?: boolean;
};

const PrepareChangeOrder = ({
  buttonTitle,
  createButtonTitle,
  modalTitle,
  mode,
  intentionId,
  redirect = true,
}: Props) => {
  const navigate = useNavigate();
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const GetConstructionManagerAccess = useAccessControl(
    "Job",
    "GetConstructionManager"
  );
  const ConverIntentionAccess = useAccessControl("Intention", "ConvertToJob");
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [editableCO, setEditableCO] = useState<ChangeOrder>(state.changeOrder);
  const [hasEditableCOChanged, setHasEditableCOChanged] =
    useState<boolean>(false);
  const [selectedCM, setSelectedCM] = useState<number>(0);
  const [savedCM, setSavedCM] = useState<boolean>(false);
  const [apiSubmissions, setApiSubmissions] = useState<number>(0);
  const [apiCompleted, setApiCompleted] = useState<number>(0);
  const apiCompletedRef = useRef<number>(0);
  apiCompletedRef.current = apiCompleted;
  //const [filteredAddresses, setFilteredAddresses] = useState<AddressTypes[]>([]);

  const updateEditableCO = (payload: any) => {
    const newEditableCO = Object.assign({}, { ...editableCO }, payload);
    setEditableCO(newEditableCO);
    setHasEditableCOChanged(true);
  };

  const addToApiCompleted = () => {
    setApiCompleted(apiCompletedRef.current + 1);
  };
  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  useEffect(() => {
    communityDropdownApiAccess && fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  const searchSalesForce = debounce((value: string) => {
    findCustomerByNameSF(
      {
        BuyerName: value,
        CommunityName: editableCO.community?.name
          ? editableCO.community.name
          : "",
      },
      (res: any) => {
        dispatch({
          type: "AvailableBuyerNames",
          payload: res.data,
        });
        setLoadingBuyerNames(false);
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
  }, 1000);

  const fetchAddresses = () => {
    getAddress(
      { projectID: editableCO.community?.projectId },
      (res: any) => {
        const patchedAddresses = res.data.map((address: AddressTypes) => {
          /*if (address.planNumber === null && editableCO.plan?.planNumber) {
            address.planNumber = editableCO.plan?.planNumber;
            address.planId = editableCO.plan?.planId;
          }
          if (
            address.planElevationNumber === null &&
            editableCO.plan?.planElevationNumber
          ) {
            address.planElevationNumber = editableCO.plan?.planElevationNumber;
            address.planElevationId = editableCO.plan?.planElevationID;
          }*/
          return address;
        });
        dispatch({
          type: "AvailableAddresses",
          payload: patchedAddresses,
        });
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
  };

  const fetchPlans = () => {
    getPlans(
      {
        ProjectID: editableCO.community?.projectId,
        ProjectNumber: editableCO.community?.projectNumber,
      },
      (res: any) => {
        dispatch({
          type: "AvailablePlans",
          payload: res.data.sort(
            (a: { planNumber: string }, b: { planNumber: string }) =>
              a.planNumber.localeCompare(b.planNumber)
          ),
        });
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
  };

  const updateCM = (cmId: string) => {
    const iCMId = parseInt(cmId);
    setSelectedCM(iCMId);
    setSavedCM(false);
    const newCM = state.constructionManagers.find(
      (cm: ConstructionManagerTypes) => cm.cmId === iCMId
    );
    if (newCM && newCM.cmName !== editableCO.cmName && iCMId !== 0) {
      upsertConstructionManager(
        {
          ChangeOrderId: intentionId || "",
          CMName: newCM.cmName,
          SuperintendentID: newCM.cmId,
        },
        (res: any) => {
          dispatch({
            type: "ChangeOrder",
            payload: {
              cm: newCM.cmName,
            },
            source: "upsertConstructionManager",
          });
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: "Construction Manager has been successfully saved",
              severity: "success",
            },
          });
          setSavedCM(true);
        },
        (err: any) => dispatch(dispatchError(err.response.data))
      );
    }
  };

  const sendToEdit = () => {
    let newApiSubmissions = 0;
    if (
      JSON.stringify(state.changeOrder.customerBuyer) !==
      JSON.stringify(editableCO.customerBuyer)
    ) {
      newApiSubmissions++;
    }
    if (
      state.changeOrder.address?.address !== editableCO.address?.address &&
      editableCO.address?.jobNumber
    ) {
      newApiSubmissions++;
    }
    setApiSubmissions(newApiSubmissions);

    if (
      JSON.stringify(state.changeOrder.customerBuyer) !==
      JSON.stringify(editableCO.customerBuyer)
    ) {
      const payload: convertIntentionType = {
        intention: intentionId as string,
        buyerChanges: {
          salesforceAccountId: editableCO.customerBuyer
            ?.salesforceAccountId as string,
          salesforceConnectionNumber: editableCO.customerBuyer
            ?.connectionNumber as string,
          buyerFirstName: editableCO.customerBuyer?.buyerFirstName as string,
          buyerLastName: editableCO.customerBuyer?.buyerLastName as string,
          buyerLegalName: editableCO.customerBuyer
            ?.contractBuyerNames as string,
        },
      };
      ConverIntentionAccess &&
        convertIntention(
          payload,
          (res: any) => {
            dispatch({
              type: "ChangeOrder",
              payload: {
                customerBuyer: editableCO.customerBuyer,
              },
              source: "convertIntention",
            });
            addToApiCompleted();
          },
          (err: any) => {
            setApiSubmissions(0);
            setApiCompleted(0);
            dispatch({
              type: "Snackbar",
              payload: {
                show: true,
                message: err.response.data.Message,
                severity: "warning",
              },
            });
          }
        );
    }

    if (
      state.changeOrder.address?.address !== editableCO.address?.address &&
      editableCO.address?.jobNumber
    ) {
      convertToJob(
        {
          intention: intentionId as string,
          jobId: editableCO.address.jobID ? editableCO.address.jobID : 0,
          jobNumber: editableCO.address.jobNumber,
        },
        (res: any) => {
          if (res.data.success === false) {
            setApiSubmissions(0);
            setApiCompleted(0);
            dispatch({
              type: "Snackbar",
              payload: {
                show: true,
                message: (
                  <span
                    style={{ alignItems: "center", display: "inline-flex" }}
                  >
                    This Address has related Change Order(s). Go to Job Summary
                    Page.
                    <Button
                      onClick={() => {
                        window.open(
                          `./job-summary?addressid=${res.data.existingJobIntentionId}`
                        );
                      }}
                      variant="outlined"
                      sx={{
                        color: "white",
                        borderColor: "white",
                        marginLeft: "8px",
                      }}
                    >
                      Open
                    </Button>
                  </span>
                ),
                severity: "warning",
              },
            });
          } else {
            dispatch({
              type: "ChangeOrder",
              payload: { address: editableCO.address },
              source: "converToJob",
            });
            addToApiCompleted();
          }
        },
        (err: any) => {
          setApiSubmissions(0);
          setApiCompleted(0);
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: err.response.data.Message,
              severity: "warning",
            },
          });
        }
      );
    }
  };

  useEffect(() => {
    if (apiCompleted === apiSubmissions) {
      if (apiSubmissions > 0) {
        dispatch({
          type: "ChangeOrder",
          payload: {
            lastSaved: new Date(),
          },
          source: "saved completed",
        });
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: "Changes to Change Order have been successfully saved",
            severity: "success",
          },
        });
        setHasEditableCOChanged(false);
        setApiSubmissions(0);
        setApiCompleted(0);
        setSavedCM(false);
        redirect
          ? navigate(`/selected?addressid=${intentionId}`)
          : setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCompleted]);

  useEffect(() => {
    setIsSaving(apiSubmissions > 0);
  }, [apiSubmissions]);

  useEffect(() => {
    FindCustomerByNameFromSFAccess &&
      searchSalesForce(
        `${editableCO.customerBuyer?.buyerFirstName} ${editableCO.customerBuyer?.buyerLastName}`
      );
    fetchAddresses();
    GetPlansAccess && fetchPlans();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableCO.community, GetPlansAccess, FindCustomerByNameFromSFAccess]);

  useEffect(() => {
    if (editableCO.address?.jobID) {
      GetConstructionManagerAccess &&
        getConstructionManagers(
          editableCO.address?.jobID,
          (res: any) => {
            const newCMs = res.data.map((cm: any) => {
              return {
                cmId: parseInt(cm.superintendentID),
                cmName: cm.cmName,
              };
            });
            // Add the ConstructionManager from the ChangeOrder if it's not on the list:
            if (
              !res.data.some(
                (cm: any) => state.changeOrder.cmName === cm.cmName
              )
            ) {
              newCMs.push({
                cmId: state.changeOrder.cmId,
                cmName: state.changeOrder.cmName,
              });
            }
            dispatch({
              type: "ConstructionManagers",
              payload: newCMs,
            });
            if (editableCO.cmName !== null) {
              const newCM = state.constructionManagers.find(
                (cm: ConstructionManagerTypes) =>
                  cm.cmName === editableCO.cmName
              );
              if (newCM) setSelectedCM(newCM.cmId);
            }
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableCO.address?.projectID, GetConstructionManagerAccess]);

  useEffect(() => {
    if (!hasEditableCOChanged) setEditableCO(state.changeOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder, hasEditableCOChanged]);

  useEffect(() => {
    if (state.constructionManagers.length > 0 && editableCO.cmName !== null) {
      const newCM = state.constructionManagers.find(
        (cm: ConstructionManagerTypes) => cm.cmName === editableCO.cmName
      );
      if (newCM) setSelectedCM(newCM.cmId);
    } else setSelectedCM(0);
  }, [editableCO.cmName, state.constructionManagers]);

  /*useEffect(() => {
    const newFilteredAddresses = state.availableAddresses.filter((item:AddressTypes) =>
      item.planId === editableCO.plan?.planId && item.planElevationId === editableCO.plan?.planElevationID);
    setFilteredAddresses(newFilteredAddresses);
  }, [editableCO.customerBuyer, state.availableAddresses]);*/

  function isEmptyOrNull(value: any): boolean {
    return value === "" || value === null || typeof value === "undefined";
  }

  const disableSave = () => {
    if (
      isEmptyOrNull(editableCO.customerBuyer) ||
      isEmptyOrNull(editableCO.customerBuyer?.salesforceAccountId) ||
      (editableCO.customerBuyer === state.changeOrder.customerBuyer &&
        state.changeOrder.address?.address === editableCO.address?.address)
    )
      return true;
    return false;
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent>
              <>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    size="medium"
                    disabled
                    value={editableCO.community || null}
                    onChange={(e, value) => {
                      updateEditableCO({
                        community: value,
                        plan: "",
                        elevation: "",
                        customerBuyer: null,
                        address: null,
                      });
                    }}
                    options={state.communities as CommunityTypes[]}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.projectNumber})`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Community"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    className={
                      (editableCO.customerBuyer?.connectionNumber &&
                        "textfield-valid") ||
                      ""
                    }
                    fullWidth
                    getOptionLabel={(option: any) => {
                      if (
                        option.contractBuyerNames &&
                        option.contractBuyerNames.trim() === "Spec"
                      ) {
                        return `${option.contractBuyerNames}`;
                      } else if (
                        option.contractBuyerNames &&
                        option.connectionNumber
                      ) {
                        return `${option.contractBuyerNames} - ${option.connectionNumber}`;
                      } else if (typeof option === "string") {
                        return option;
                      }
                      return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
                    }}
                    loading={loadingBuyerName}
                    multiple={false}
                    onChange={(e, value) => {
                      updateEditableCO({ customerBuyer: value });
                    }}
                    onInputChange={(e, value) => {
                      if (!isNil(value) && value.length >= 3) {
                        if (e && e?.type === "click") {
                          updateEditableCO({ customerBuyer: value });
                        }
                        if (e && e?.type !== "click") {
                          setLoadingBuyerNames(true);
                          FindCustomerByNameFromSFAccess &&
                            searchSalesForce(value);
                        }
                      }
                    }}
                    options={
                      (state.availableBuyerNames as CustomerBuyerTypes[]) || ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={
                          (editableCO.customerBuyer?.connectionNumber &&
                            "Buyer in Salesforce") ||
                          null
                        }
                        label="Buyer"
                        variant="outlined"
                      />
                    )}
                    size="medium"
                    value={editableCO.customerBuyer || null}
                  />
                </Box>
                <Box gap={2} marginY={(theme) => theme.spacing(2)}>
                  {/* <Box padding={1} flex={1}>
                    <RadioGroup
                      sx={{
                        flexDirection: "row",
                      }}
                      aria-labelledby="adjust by"
                      value={state.changeOrder.addressType}
                      name="adjustBy"
                      onChange={(e, value) => {
                        if (value === "freeForm") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              addressFreeform: "TBD / Preliminary",
                            },
                          });
                        }
                        dispatch({
                          type: "ChangeOrder",
                          payload: {
                            addressType: value,
                          },
                        });
                      }}
                    >
                      <FormControlLabel
                        value={"knownAddress"}
                        control={<Radio size="small" />}
                        label={"Address"}
                      />
                      <FormControlLabel
                        value={"lotBlock"}
                        control={<Radio size="small" />}
                        label={"Lot and Block"}
                      />
                      <FormControlLabel
                        value={"freeForm"}
                        control={<Radio size="small" />}
                        label={"Freeform"}
                      />
                    </RadioGroup>
                  </Box> */}
                  {editableCO.addressType === "knownAddress" && (
                    <Autocomplete
                      className={
                        (editableCO.address?.jobID && "textfield-valid") || ""
                      }
                      disabled={state.changeOrder.address?.jobID !== null}
                      multiple={false}
                      fullWidth
                      getOptionDisabled={(option: any) =>
                        (option.planId &&
                          option.planId !== state.changeOrder.plan?.planId) ||
                        (option.planElevationId &&
                          option.planElevationId !==
                            state.changeOrder.plan?.planElevationID)
                      }
                      size="medium"
                      //defaultValue={""}
                      value={editableCO.address || null}
                      onChange={(e, value) => {
                        if (isNull(value)) {
                          updateEditableCO({
                            address: null,
                            plan: null,
                            elevation: null,
                          });
                        } else if (value) {
                          const {
                            planNumber,
                            planElevationNumber,
                            abbreviation,
                            description,
                          } = value;
                          updateEditableCO({
                            address: value,
                            elevation: {
                              planNumber,
                              planElevationNumber,
                              abbreviation,
                            },
                            plan: {
                              abbreviation,
                              description,
                              planElevationNumber,
                              planNumber,
                            },
                          });
                        }
                      }}
                      options={
                        (state.availableAddresses as AddressTypes[]) || ""
                      }
                      getOptionLabel={(option: any) => {
                        if (typeof option === "string") return option;
                        if (option.address === "TBD / Preliminary")
                          return option.address;
                        return option && option.address !== null
                          ? `${option.address} ${
                              !isNull(option.planNumber) &&
                              !isNull(option.planElevationNumber) &&
                              !isEmpty(option.planNumber) &&
                              !isEmpty(option.planElevationNumber)
                                ? `(${option.planNumber} / ${option.planElevationNumber})`
                                : ""
                            } | ${option.projectNumber}-${option.jobNumber}`
                          : "";
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            (editableCO.address?.jobID && "Address in BRIX") ||
                            null
                          }
                          variant="outlined"
                          label="Address"
                          fullWidth
                        />
                      )}
                    />
                  )}

                  {state.changeOrder.addressType === "lotBlock" && (
                    <>
                      <TextField
                        label="Lot"
                        value={state.changeOrder.lot}
                        disabled
                        onChange={(e: any) =>
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              addressFreeform: "",
                              lot: e.target.value,
                            },
                            source: "PCO Lot change",
                          })
                        }
                        variant="outlined"
                      />
                      <TextField
                        sx={{
                          marginX: 1,
                        }}
                        label="Block"
                        disabled
                        value={state.changeOrder.block}
                        onChange={(e: any) =>
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              addressFreeform: "",
                              block: e.target.value,
                            },
                            source: "PCO Block change",
                          })
                        }
                        variant="outlined"
                      />
                    </>
                  )}

                  {state.changeOrder.addressType === "freeForm" && (
                    <>
                      <TextField
                        label="Free Form"
                        fullWidth
                        defaultValue={"TBD / Preliminary"}
                        disabled
                        value={state.changeOrder.addressFreeform}
                        onChange={(e: any) =>
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              lot: "",
                              block: "",
                              addressFreeform: e.target.value,
                            },
                            source: "PCO FreeForm change",
                          })
                        }
                        variant="outlined"
                      />
                    </>
                  )}
                </Box>

                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    defaultValue={""}
                    disabled
                    fullWidth
                    getOptionLabel={(option) => {
                      return `${option.planNumber}${
                        option.description ? ` - ${option.description}` : ""
                      }`;
                    }}
                    multiple={false}
                    onChange={(e, value) => {
                      dispatch({
                        type: "ChangeOrder",
                        payload: {
                          plan: value,
                        },
                        source: "PCP Elevation change",
                      });
                      if (!isNull(value)) {
                        if (value.projectID) {
                          const {
                            projectID,
                            projectNumber,
                            planId,
                            planNumber,
                          } = value;
                          GetPlanElevationsAccess &&
                            getPlanElevations(
                              { projectID, projectNumber, planId, planNumber },
                              (res: any) => {
                                dispatch({
                                  type: "AvailableElevations",
                                  payload: res.data,
                                });
                              },
                              (err: any) =>
                                dispatch(
                                  dispatchError({
                                    message: err.message,
                                    statusText: err.response.statusText,
                                    title: err.response.data.title,
                                    status: err.response.status,
                                    detail: err.response.data.detail,
                                    data: err.response.data,
                                  })
                                )
                            );
                        }
                      }
                    }}
                    onInputChange={(e, value) => {
                      if (!isNil(e)) {
                        if (e.type !== "click") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              planOverride: value,
                            },
                            source: "PCO Elevation click",
                          });
                        }
                      }
                    }}
                    options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
                      return obj.description;
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // error={
                        //   state.changeOrder.addressType === "knownAddress" &&
                        //   state?.changeOrder?.address?.planNumber !==
                        //     state?.changeOrder?.plan?.planNumber &&
                        //   true
                        // }
                        // helperText={
                        //   state.changeOrder.addressType === "knownAddress" &&
                        //   state?.changeOrder?.address?.planNumber !==
                        //     state?.changeOrder?.plan?.planNumber &&
                        //   "*Address plan has been overriden"
                        // }
                        variant="outlined"
                        label="Plan"
                        fullWidth
                      />
                    )}
                    size="medium"
                    value={state.changeOrder.plan || null}
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    disabled
                    size="medium"
                    defaultValue={""}
                    value={state.changeOrder.elevation || null}
                    onInputChange={(e, value) => {
                      if (!isNil(e)) {
                        if (e.type !== "click") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              planElevationOverride: value,
                            },
                            source: "PCO Elevation click",
                          });
                        }
                      }
                    }}
                    onChange={(e, value) => {
                      if (isNil(value)) {
                        dispatch({
                          type: "ChangeOrder",
                          payload: {
                            elevation: {
                              planElevationNumber: "",
                            },
                          },
                          source: "PCO Elevation change empty",
                        });
                      } else
                        dispatch({
                          type: "ChangeOrder",
                          payload: {
                            elevation: value,
                          },
                          source: "PCO Elevation change",
                        });
                    }}
                    options={state.availableElevations as ElevationTypes[]}
                    getOptionLabel={(option) => `${option.planElevationNumber}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // error={
                        //   state.changeOrder.addressType === "knownAddress" &&
                        //   state?.changeOrder?.address?.planElevationNumber !==
                        //     state?.changeOrder?.elevation
                        //       ?.planElevationNumber &&
                        //   true
                        // }
                        // helperText={
                        //   state.changeOrder.addressType === "knownAddress" &&
                        //   state?.changeOrder?.address?.planElevationNumber !==
                        //     state?.changeOrder?.elevation
                        //       ?.planElevationNumber &&
                        //   "*Address elevation has been overriden"
                        // }
                        variant="outlined"
                        label="Elevation"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <FormControl fullWidth>
                    <InputLabel
                      className={(savedCM && "textfield-valid") || ""}
                      id="pco-cm-label"
                    >
                      Construction Manager
                    </InputLabel>
                    <Select
                      className={(savedCM && "textfield-valid") || ""}
                      disabled={
                        state.changeOrder.address?.address ===
                          "TBD / Preliminary" ||
                        state.changeOrder.address?.jobHasSubmittedCOs
                      }
                      labelId="pco-cm-label"
                      label="Construction Manager"
                      id="pco-cm-select"
                      value={String(selectedCM)}
                      variant="outlined"
                      onChange={(e: SelectChangeEvent) =>
                        updateCM(e.target.value)
                      }
                    >
                      {state.constructionManagers.map(
                        (constructionManager: ConstructionManagerTypes) => (
                          <MenuItem
                            key={constructionManager.cmId}
                            value={constructionManager.cmId}
                          >
                            {constructionManager.cmName}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {savedCM && (
                      <FormHelperText className="textfield-valid">
                        Construction Manager has been saved
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setHasEditableCOChanged(false);
                  setSavedCM(false);
                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                disabled={isSaving || disableSave()}
                loading={isSaving}
                onClick={sendToEdit}
                variant="contained"
              >
                {createButtonTitle}
              </LoadingButton>
            </DialogActions>
          </>
        }
        buttonText={mode === "create" ? buttonTitle : ""}
        icon={mode === "edit" ? <Edit /> : null}
        title={modalTitle}
      />
    </>
  );
};

export default PrepareChangeOrder;
